import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { Container } from '../components/Animations'
import { IconButton, PrimaryButton } from '../components/Button'
import PageHeader from '../components/PageHeader'
import Table from '../components/Table'
import Radom, { ICheckoutSession } from '../state/Radom'
import Modal from '../components/Modal'
import NumberInput from '../components/NumberInput'
import { errorToast, infoToast, successToast } from '../util/Util'
import { PAY_BASE_URL, RADOM_COLORS } from '../util/Constants'
import TimeText from '../components/TimeText'
import { InputLabel, TextInput } from '../components/Input'
import LinkExternal from '../components/LinkExternal'
import Copy from '../icons/Copy'
import ConfirmButtonModal from '../components/ConfirmButtonModal'
import Dropdown, { DropdownItem, DropdownLink } from '../components/Dropdown'
import Dots from '../icons/Dots'
import styled from 'styled-components'
import { ManagedPaymentMethod } from '../util/Managed'
import { ManagedPaymentMethodSelector } from '../components/ManagedMethodMultiselector'
import Close from '../icons/Close'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { RADOM_TERMINAL_ENABLED_KEY } from './Organization/Profile'
import Pagination from '../components/Pagination'

export const StatusBadge = styled.div<{ status: 'awaitingConfirmation' | 'processing' | 'complete' | 'active' | 'pending' | 'success' | 'cancelled' | 'expired' | 'refunded' | 'paid' | 'voided' | 'overdue' }>`
  text-transform: capitalize;
  padding: 5px 10px;
  border-radius: 5px;
  color: white;
  font-size: 14px;
  width: fit-content;

  ${({ status }) => {
    let bgColor = RADOM_COLORS.ERROR_LIGHT as string
    let textColor = RADOM_COLORS.ERROR as string

    switch (status) {
    case 'active':
    case 'success':
    case 'paid':
    case 'complete':
      bgColor = RADOM_COLORS.SUCCESS_LIGHT as string
      textColor = RADOM_COLORS.SUCCESS as string
      break
    case 'awaitingConfirmation':
    case 'pending':
    case 'processing':
      bgColor = RADOM_COLORS.GRAY_MED
      textColor = RADOM_COLORS.GRAY_DARKEST
      break
    case 'refunded':
      bgColor = RADOM_COLORS.WARNING_LIGHT
      textColor = RADOM_COLORS.WARNING
      break
    }

    return `
    background-color: ${bgColor};
    color: ${textColor};
  `
  }};
`

const CheckoutSessions = observer((): React.ReactElement => {
  const navigate = useNavigate()
  const inputRefs = {}

  const pageSize = 20
  const [curPage, setCurPage] = useState(0)
  const [totalSessions, setTotalSessions] = useState(0)

  const [isLoadingCheckoutSessions, setIsLoadingCheckoutSessions] = useState(false)
  const [checkoutSessions, setCheckoutSessions] = useState<ICheckoutSession[]>([])

  const [isCreatingSession, setIsCreatingSession] = useState(false)
  const [modalVisible, setModalVisible] = useState(false)
  const [sessionTotal, setSessionTotal] = useState('')
  const [selectedPaymentMethods, setSelectedPaymentMethods] = useState([] as ManagedPaymentMethod[])

  const [terminalModeVisible, setTerminalModeVisible] = useState(false)
  const [pendingCheckoutSession, setPendingCheckoutSession] = useState<ICheckoutSession | undefined>(undefined)

  const [searchParams, setSearchParams] = useSearchParams()

  const loadCheckoutSessions = (): void => {
    setIsLoadingCheckoutSessions(true)
    Radom.listCheckoutSessions(curPage * pageSize, pageSize)
      .then(c => {
        setCheckoutSessions(c.data)
        setTotalSessions(c.total)
      })
      .finally(() => setIsLoadingCheckoutSessions(false))
  }

  useEffect(() => {
    setCheckoutSessions([])
    loadCheckoutSessions()
  }, [curPage])

  useEffect(() => {
    setTimeout(() => {
      if (terminalModeVisible) {
        document.body.requestFullscreen()
      } else if (document.fullscreenElement && document.fullscreenEnabled) {
        document.exitFullscreen()
      }
    })
  }, [terminalModeVisible])

  return <Container>
    <div style={{ flexGrow: 1, display: 'flex', flexDirection: 'column', rowGap: 20 }}>
      <div style={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '20px' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <PageHeader title="Checkout sessions" subTitle="Manage your checkout sessions" />
          {
            localStorage.getItem(RADOM_TERMINAL_ENABLED_KEY) &&
            <PrimaryButton onClick={() => setModalVisible(true)} style={{ gap: 8 }}>
              <span style={{ fontSize: 20, marginTop: -3 }}>+</span>
              <span>Create checkout session</span>
            </PrimaryButton>
          }
        </div>
        {
          searchParams.get('checkoutSessionId') &&
            <div style={{ fontSize: 14, color: RADOM_COLORS.GRAY_DARKER, display: 'flex', alignItems: 'center', gap: 8 }}>
              <span>Filtered by ID {searchParams.get('checkoutSessionId')}</span>
              <IconButton
                style={{ padding: 0, margin: 0 }}
                onClick={() => {
                  setSearchParams(params => {
                    params.delete('checkoutSessionId')
                    return params
                  })
                }}
              >
                <Close style={{ width: 12, height: 12 }} />
              </IconButton>
            </div>
        }
        <Table
          borderless
          disableOnClickState
          onClickRow={i => {
            if (!localStorage.getItem(RADOM_TERMINAL_ENABLED_KEY) || checkoutSessions[i].sessionStatus !== 'pending') {
              navigate(`/checkout_session/${checkoutSessions[i].id}`)
            }

            if (checkoutSessions[i].sessionStatus !== 'pending') {
              return
            }

            if (!localStorage.getItem(RADOM_TERMINAL_ENABLED_KEY)) {
              return
            }

            setPendingCheckoutSession(checkoutSessions[i])
            setTerminalModeVisible(true)
          }}
          isLoading={isLoadingCheckoutSessions}
          emptyPlaceholderText='No checkout sessions to show'
          headers={[
            <span key="id">Session ID</span>,
            <span key="status">Session status</span>,
            // <span key="status">Total</span>,
            <span key="created">Created</span>,
            <span key="action"></span>
          ]}
          items={checkoutSessions
            .filter(c => {
              const checkoutSessionId = searchParams.get('checkoutSessionId')
              if (checkoutSessionId) {
                return c.id === checkoutSessionId
              }
              return true
            })
            .map(c => [
              <div key="url" style={{ display: 'flex', alignItems: 'center', width: '100%', gap: '5px' }}>
                <TextInput value={c.id} ref={r => r && (inputRefs[c.id] = r)} style={{ maxWidth: '280px', height: 'fit-content' }} disabled />
                <IconButton style={{ padding: 0 }}>
                  <Copy style={{ width: 20 }} onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    inputRefs[c.id].select()
                    inputRefs[c.id].setSelectionRange(0, 99999)
                    navigator.clipboard.writeText(inputRefs[c.id].value)
                    infoToast('Copied checkout session URL!')
                  }} />
                </IconButton>
                <IconButton
                  style={{ padding: 0 }}
                  onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    window.open(`${PAY_BASE_URL}/checkout/${c.id}`, '_blank')
                  }}>
                  <LinkExternal stroke={RADOM_COLORS.GRAY_DARK} width={20} />
                </IconButton>
              </div>,
              <span key='status'><StatusBadge status={c.sessionStatus}>{c.sessionStatus}</StatusBadge></span>,
              // <span key='currency'>{c.currency} {c.total}</span>,
              <div key="created" style={{ color: RADOM_COLORS.GRAY_DARKER }}>
                <TimeText date={new Date(c.createdAt)} />
              </div>,
              <div key={c.id} style={{ display: 'flex' }} onClick={e => e.stopPropagation()}>
                {
                  c.sessionStatus === 'pending' &&
              <Dropdown
                noChevron
                selectedContent={<Dots width={15} />}
                dropdownContent={
                  <div style={{ fontSize: 14, whiteSpace: 'nowrap' }}>
                    <DropdownLink to={`/checkout_session/${c.id}`}>
                      View session
                    </DropdownLink>
                    <ConfirmButtonModal
                      title='Cancel session'
                      description='Are you sure you want to cancel this checkout session? This action cannot be undone.'
                      action='Cancel session'
                      onConfirm={async () => {
                        await Radom.cancelCheckoutSession(c.id)
                          .then(() => {
                            successToast('Cancelled checkout session')
                            loadCheckoutSessions()
                          })
                          .catch(err => {
                            errorToast(err.response?.data?.error || err.message)
                          })
                      }}
                      ActionComponent={props => <DropdownItem {...props}>Cancel session</DropdownItem>}
                    />
                  </div>
                }
              />
                }
              </div>
            ])} />
      </div>
      {
        !isLoadingCheckoutSessions && <Pagination
          curPage={curPage}
          numPages={Math.ceil(totalSessions / pageSize)}
          onPageClick={page => setCurPage(page)}
        />
      }
    </div>

    <Modal visible={modalVisible} onClose={() => setModalVisible(false)}>
      <div style={{
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        padding: 30,
        borderBottom: `1px solid ${RADOM_COLORS.GRAY_MED}`
      }}>
        <span style={{ fontSize: 24, fontWeight: 300 }}>Create checkout session</span>
        <IconButton type='button' onClick={() => setModalVisible(false)}>
          <Close style={{ width: '14px' }} />
        </IconButton>
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', padding: 30, gap: 20, fontSize: 14 }}>

        <div style={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
          <ManagedPaymentMethodSelector
            allowTestnets
            selectedMethods={selectedPaymentMethods}
            setSelectedMethods={setSelectedPaymentMethods} />
        </div>

        <div style={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
          <InputLabel>Total</InputLabel>
          <NumberInput
            value={sessionTotal}
            onUserInput={(e) => setSessionTotal(e)}
            fontSize='14'
            align='right'
            required={true}
          />
        </div>

      </div>
      <div style={{ width: '100%', padding: 30, background: RADOM_COLORS.GRAY_LIGHTEST }}>
        <PrimaryButton
          isLoading={isCreatingSession}
          style={{ width: '100%' }}
          onClick={() => {
            setIsCreatingSession(true)
            Radom.createCheckoutSession({
              gateway: {
                managed: {
                  methods: selectedPaymentMethods.map(m => ({
                    network: m.hostChain?.name || m.name,
                    token: m.hostChain?.tokenAddress ?? undefined
                  }))
                }
              },
              currency: 'USD',
              total: Number(sessionTotal) || 0,
              cancelUrl: 'https://google.com',
              successUrl: 'https://ethereum.org'
            })
              .then(() => {
                loadCheckoutSessions()
                setModalVisible(false)
                successToast('Created checkout session!')
              })
              .catch(err => errorToast(err.response?.data?.error || err.toString()))
              .finally(() => setIsCreatingSession(false))
          }}>
          Create checkout session
        </PrimaryButton>
      </div>
    </Modal>

    {
      terminalModeVisible &&
      <div style={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 100
      }}>
        <FrameComponent url={`${PAY_BASE_URL}/checkout/${pendingCheckoutSession?.id}?posMode=true`} />

        <div style={{ position: 'absolute', bottom: 20, right: 20 }}>
          <PrimaryButton
            style={{ backgroundColor: 'rgba(0,0, 0, 0.1)', color: 'white' }}
            onClick={() => {
              setTerminalModeVisible(false)
              loadCheckoutSessions()
            }}>
              Close
          </PrimaryButton>
        </div>
      </div>
    }
  </Container>
})

const FrameComponent = React.memo(function IframeComponent({ url }: { url: string }) {
  return <iframe
    style={{
      border: 0,
      width: '85%',
      height: '85%',
      transformOrigin: '0 0',
      transform: 'scale(1.2)'
    }}
    key="iframe"
    src={url}
  />
})

export default CheckoutSessions
