import { Viewer, Worker } from '@react-pdf-viewer/core'
import { thumbnailPlugin } from '@react-pdf-viewer/thumbnail'
import React, { ReactElement, useMemo } from 'react'
import { PrimaryButton } from '../../components/Button'
import FileInput from '../../components/FileInput'
import { InputLabel } from '../../components/Input'
import PageHeader from '../../components/PageHeader'
import Spinner from '../../components/Spinner'
import { pageThumbnailPlugin } from '../../util/Util'

interface IProps {
  state: {
    formationDocument: string
    ownershipDocument: string
    formationDocumentFile?: File
    ownershipDocumentFile?: File
  }
  isSubmitting: boolean
  onUpdate: (value: any) => void
  onSubmit: () => void
}

const Documents = ({ state, isSubmitting, onUpdate, onSubmit }: IProps): ReactElement => {
  const formationDocumentThumbnail = thumbnailPlugin({ renderSpinner: () => <Spinner />, thumbnailWidth: 100 })
  const ownershipDocumentThumbnail = thumbnailPlugin({ renderSpinner: () => <Spinner />, thumbnailWidth: 100 })
  const { Cover: CoverFormation } = formationDocumentThumbnail
  const { Cover: CoverOwnership } = ownershipDocumentThumbnail

  const formationDocumentPageThumbnail = pageThumbnailPlugin({
    PageThumbnail: (
      <div style={{ cursor: 'pointer', width: '100px' }} onClick={() => window.open(state.formationDocument)}>
        <CoverFormation getPageIndex={() => 0} width={100} />
      </div>
    )
  })
  const ownershipDocumentPageThumbnail = pageThumbnailPlugin({
    PageThumbnail: (
      <div style={{ cursor: 'pointer', width: '100px' }} onClick={() => window.open(state.ownershipDocument)}>
        <CoverOwnership getPageIndex={() => 0} width={100} />
      </div>
    )
  })

  const isValid = useMemo(() => {
    return (!!state.formationDocument || !!state.formationDocumentFile) &&
    (!!state.ownershipDocument || !!state.ownershipDocumentFile)
  }, [state])

  const onImageFileChange = async (event: React.ChangeEvent<HTMLInputElement>, prop: string): Promise<void> => {
    const fileList = event.target.files
    if (fileList && fileList.length > 0) {
      onUpdate({ [prop]: fileList[0] })
    }
  }

  return (
    <Worker workerUrl='https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js'>
      <div style={{ display: 'flex', flexDirection: 'column', gap: 20, fontSize: 14 }}>
        <div style={{ marginBottom: 20 }}>
          <PageHeader title="Document submission" subTitle="Upload required documents for verification" />
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
          <InputLabel>
          Formation document
          </InputLabel>
          <div style={{ display: 'flex', gap: 10, alignItems: 'center' }}>
            <FileInput
              value={state.formationDocumentFile}
              onChange={async (evt) => await onImageFileChange(evt, 'formationDocumentFile')}
            />
            { state.formationDocument && (
              <Viewer
                fileUrl={state.formationDocument}
                plugins={[formationDocumentThumbnail, formationDocumentPageThumbnail]}
              />
            )}
          </div>
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
          <InputLabel>
          Ownership document
          </InputLabel>
          <div style={{ display: 'flex', gap: 10, alignItems: 'center' }}>
            <FileInput
              value={state.ownershipDocumentFile}
              onChange={async (evt) => await onImageFileChange(evt, 'ownershipDocumentFile')}
            />
            { state.ownershipDocument && (
              <Viewer
                fileUrl={state.ownershipDocument}
                plugins={[ownershipDocumentThumbnail, ownershipDocumentPageThumbnail]}
              />
            )}
          </div>
        </div>
        <PrimaryButton disabled={!isValid} isLoading={isSubmitting} onClick={onSubmit}>
          Continue
        </PrimaryButton>
      </div>
    </Worker>
  )
}

export default Documents
