import React, { ReactElement, useMemo } from 'react'

import { PrimaryButton } from '../../components/Button'
import Dropdown, { DropdownItem } from '../../components/Dropdown'
import { InputLabel, TextAreaWithLabel, TextInputWithLabel } from '../../components/Input'
import PageHeader from '../../components/PageHeader'
import { BusinessTypes, BusinessBasics as State } from '../../state/Radom'

interface IProps {
  state: State
  isSubmitting: boolean
  onUpdate: (value: any) => void
  onSubmit: () => void
}

const BusinessBasics = ({ state, isSubmitting, onUpdate, onSubmit }: IProps): ReactElement => {
  const isValid = useMemo(() => {
    return !!state.name && !!state.description && !!state.website && !!state.email
  }, [state])

  const businessTypes = useMemo(
    () => Object.entries(BusinessTypes).map(([key, value]) => (
      <DropdownItem key={key} onClick={() => {
        onUpdate({ businessType: key })
      }}>
        {value}
      </DropdownItem>
    ))
    , [])

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: 20, fontSize: 14 }}>
      <div style={{ marginBottom: 20 }}>
        <PageHeader title="Begin with the basics" subTitle="Let's gather essential information about your business" />
      </div>
      <TextInputWithLabel
        required
        label="Business name"
        placeholder="Enter your business name"
        value={state.name}
        onChange={(event) => {
          const eventTarget = event.target as HTMLInputElement
          onUpdate({ name: eventTarget.value })
        }}
      />
      <TextAreaWithLabel
        required
        label="Business description"
        placeholder="Describe your business"
        value={state.description}
        onChange={(event) => {
          const eventTarget = event.target as HTMLTextAreaElement
          onUpdate({ description: eventTarget.value })
        }}
      />
      <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
        <InputLabel>Business type</InputLabel>
        <Dropdown
          selectedContent={BusinessTypes[state.businessType] || 'Select business type'}
          dropdownContent={businessTypes}
        />
      </div>
      <TextInputWithLabel
        required
        label="Business website"
        placeholder="Enter your business website"
        value={state.website}
        onChange={(event) => {
          const eventTarget = event.target as HTMLInputElement
          onUpdate({ website: eventTarget.value })
        }}
      />
      <TextInputWithLabel
        required
        label="Business email"
        placeholder="Enter your business email"
        value={state.email}
        onChange={(event) => {
          const eventTarget = event.target as HTMLInputElement
          onUpdate({ email: eventTarget.value })
        }}
      />
      <PrimaryButton disabled={!isValid} isLoading={isSubmitting} onClick={onSubmit}>
          Continue
      </PrimaryButton>
    </div>
  )
}

export default BusinessBasics
