import { City, Country, State } from 'country-state-city'
import React, { ReactElement, useEffect, useMemo, useReducer } from 'react'

import { PrimaryButton } from '../../components/Button'
import { InputLabel, TextInputWithLabel } from '../../components/Input'
import PageHeader from '../../components/PageHeader'
import SearchMultiselect from '../../components/SearchMultiselect'
import { BusinessAddress as BusinessAddressState } from '../../state/Radom'

interface IProps {
  state: BusinessAddressState
  isSubmitting: boolean
  onUpdate: (value: any) => void
  onSubmit: () => void
}

const BusinessAddress = ({ state, isSubmitting, onUpdate, onSubmit }: IProps): ReactElement => {
  const [isValid, updateIsValid] = useReducer((prev, next) => { return { ...prev, ...next } }, {
    streetLine: state.streetLine !== '',
    city: state.city !== '',
    state: state.state !== '',
    postalCode: state.postalCode !== '',
    country: state.country !== ''
  })

  const isFormValid = useMemo(() => Object.values(isValid).every((value) => value), [isValid])
  const canSubmit = useMemo(() => {
    return !!state.streetLine && !!state.city && !!state.state && !!state.postalCode && !!state.country
  }, [state])

  const countries = useMemo(() => Country
    .getAllCountries()
    .map((country) => ({
      key: country.isoCode,
      value: country.name
    })),
  [])

  const states = useMemo(() => State
    .getStatesOfCountry(state.country)
    .map((state) => ({
      key: state.isoCode,
      value: state.name
    })),
  [state.country])

  const cities = useMemo(() => City
    .getCitiesOfState(state.country, state.state)
    .map((city) => ({
      key: city.name,
      value: city.name
    })),
  [state.country, state.state])

  const selectedCountry = useMemo(
    () => countries.find((country) => country.key === state.country),
    [state.country, countries]
  )

  const selectedState = useMemo(
    () => states.find((s) => s.key === state.state),
    [state.state, states]
  )

  const selectedCity = useMemo(
    () => cities.find((city) => city.key === state.city),
    [state.city, cities]
  )

  useEffect(() => {
    if (!!state.country && states.length === 0) {
      onUpdate({ state: '-', city: '-' })
    }
  }, [state.country, states])

  useEffect(() => {
    if (!!state.state && cities.length === 0) {
      onUpdate({ city: '-' })
    }
  }, [state.state, cities])

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: 20, fontSize: 14 }}>
      <div style={{ marginBottom: 20 }}>
        <PageHeader title="Tell us where you're located" subTitle="Share your business's physical address" />
      </div>
      <TextInputWithLabel
        label="Street line"
        required
        value={state.streetLine}
        onChange={(e) => {
          const eventTarget = e.target as HTMLInputElement
          updateIsValid({ streetLine: eventTarget.value !== '' })
          onUpdate({ streetLine: eventTarget.value })
        }}
      />
      <div style={{ display: 'flex', gap: 10 }}>
        <div style={{ display: 'flex', flexDirection: 'column', gap: 10, flex: 1 }}>
          <InputLabel>Country</InputLabel>
          <SearchMultiselect
            isSingleSelect
            placeholder="Select country"
            dropdownPlaceholder="No countries found"
            itemArray={countries}
            selectedItems={selectedCountry ? [selectedCountry] : []}
            setSelectedItems={(items) => {
              const selected = items[0]
              if (selected) {
                onUpdate({ country: selected.key, state: '', city: '' })
              }
            }}
            keyExtractor={(country) => country.value}
            labelExtractor={(country) => country.value}
          />
        </div>
        <div style={{ flex: 1 }}>
          <div style={{ display: 'flex', flexDirection: 'column', gap: 10, flex: 1 }}>
            <InputLabel>State</InputLabel>
            <SearchMultiselect
              isSingleSelect
              disabled={!state.country || states.length === 0}
              placeholder={!!state.country && states.length === 0 ? '-' : 'Select a state'}
              dropdownPlaceholder="No states found"
              itemArray={states}
              selectedItems={selectedState ? [selectedState] : []}
              setSelectedItems={(items) => {
                const selected = items[0]
                if (selected) {
                  onUpdate({ state: selected.key, city: '' })
                }
              }}
              keyExtractor={(state) => state.value}
              labelExtractor={(state) => state.value}
            />
          </div>
        </div>
      </div>
      <div style={{ display: 'flex', gap: 10 }}>
        <div style={{ display: 'flex', flexDirection: 'column', gap: 10, flex: 1 }}>
          <InputLabel>City</InputLabel>
          <SearchMultiselect
            isSingleSelect
            disabled={!state.state || cities.length === 0}
            placeholder={!!state.state && cities.length === 0 ? '-' : 'Select a city'}
            dropdownPlaceholder="No cities found"
            itemArray={cities}
            selectedItems={selectedCity ? [selectedCity] : []}
            setSelectedItems={(items) => {
              const selected = items[0]
              if (selected) {
                onUpdate({ city: selected.key })
              }
            }}
            keyExtractor={(city) => city.value}
            labelExtractor={(city) => city.value}
          />
        </div>
        <div style={{ flex: 1 }}>
          <TextInputWithLabel
            label="Postal code"
            required
            value={state.postalCode}
            onChange={(e) => {
              const eventTarget = e.target as HTMLInputElement
              updateIsValid({ postalCode: eventTarget.validity.valid })
              onUpdate({ postalCode: eventTarget.value })
            }}
          />
        </div>
      </div>
      <PrimaryButton
        disabled={!canSubmit || !isFormValid}
        isLoading={isSubmitting}
        onClick={onSubmit}
      >
          Continue
      </PrimaryButton>
    </div>
  )
}

export default BusinessAddress
