import React from 'react'
import styled from 'styled-components'
import { BaseInputStyles } from './Input'

const StyledInput = styled.input<{ error?: boolean, fontSize?: string, align?: string }>`
  ${BaseInputStyles}
`

export default React.memo(function InnerInput({
  value,
  onUserInput,
  placeholder,
  prependSymbol,
  ...rest
}: {
  value?: string | number
  onUserInput: (input: string) => void
  error?: boolean
  fontSize?: string
  align?: 'right' | 'left'
  prependSymbol?: string | undefined
} & Omit<React.HTMLProps<HTMLInputElement>, 'ref' | 'onChange' | 'as'>) {
  function handleChange(event: React.ChangeEvent<HTMLInputElement>): void {
    const newValue = event.target.value
    if (/^\d*\.?\d*$/.test(newValue)) {
      onUserInput(newValue)
    }
  }

  function handleKeyPress(event: React.KeyboardEvent<HTMLInputElement>): void {
    const charCode = event.code
    if (charCode === 'Minus') {
      event.preventDefault()
    } else if (charCode !== 'delete' && charCode > 'a' && (charCode < '0' || charCode > '9')) {
      event.preventDefault()
    }
  }

  return (
    <StyledInput
      value={value}
      onChange={handleChange}
      onKeyPress={handleKeyPress}
      inputMode="numeric"
      autoComplete="off"
      autoCorrect="off"
      placeholder={placeholder ?? '0.00'}
      minLength={1}
      maxLength={79}
      spellCheck="false"
      {...rest}
    />
  )
})
