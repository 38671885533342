import React, { ReactElement, useEffect, useState } from 'react'
import PageHeader from '../../components/PageHeader'
import Table from '../../components/Table'
import Radom, { Payout } from '../../state/Radom'
import { observer } from 'mobx-react'
import { PrimaryButton } from '../../components/Button'
import { formatCurrency, useTestMode } from '../../util/Util'
import { Container } from '../../components/Animations'
import Pagination from '../../components/Pagination'
import CreatePayoutModal from './CreatePayoutModal'
import PayoutModal from './PayoutModal'
import TimeText from '../../components/TimeText'
import { StatusBadge } from '../CheckoutSessions'
import { camelCase } from 'lodash'

const Payouts = observer((): ReactElement => {
  const [isLoading, setIsLoading] = useState(false)

  const pageSize = 10
  const [curPage, setCurPage] = useState(0)
  const [payouts, setPayouts] = useState<Payout[]>([])
  const [totalPayoutsSize, setTotalPayoutsSize] = useState(0)

  const [testMode] = useTestMode()

  const [selectedPayoutId, setSelectedPayoutId] = useState<string>()
  const [isCreatePayoutModalOpen, setIsCreatePayoutModalOpen] = useState(false)
  const [isPayoutModalOpen, setIsPayoutModalOpen] = useState(false)

  const loadPayouts = async (): Promise<void> => {
    setIsLoading(true)
    Radom.listPayouts(curPage * pageSize, pageSize, testMode)
      .then(res => {
        setIsLoading(false)
        setPayouts(res.data)
        setTotalPayoutsSize(res.total)
      })
      .catch(() => {})
  }

  useEffect(() => {
    loadPayouts()
  }, [testMode, curPage])

  return <Container>
    <div style={{ flexGrow: 1, display: 'flex', flexDirection: 'column', rowGap: 20 }}>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <PageHeader title='Payouts' subTitle='Manage and view your payouts' />
        <PrimaryButton style={{ gap: 8 }} onClick={() => setIsCreatePayoutModalOpen(true)}>
          <span style={{ fontSize: 20, marginTop: -3 }}>+</span>
          <span>Create payout</span>
        </PrimaryButton>
      </div>
      <Table
        onClickRow={(i) => {
          setSelectedPayoutId(payouts[i].id)
          setIsPayoutModalOpen(true)
        }}
        borderless
        headers={['ID', 'Status', 'Total payout', 'Fee', 'Completed', 'Created'].map(h => <span key={h}>{h}</span>)}
        items={
          payouts.map((p, i) => [
            <div key={p.id}>{p.id}</div>,
            <div key={p.status}>
              <StatusBadge status={camelCase(p.status)}>{p.status}</StatusBadge>
            </div>,
            <div key={p.id}>{formatCurrency(p.totalPayoutUsd, 'USD')}</div>,
            <div key={p.id}>{formatCurrency(p.estimatedFeeUsd, 'USD')}</div>,
            <div key={p.id}>{p.completedAt ? <TimeText date={new Date(p.completedAt)} /> : '-' }</div>,
            <div key={p.id}><TimeText date={new Date(p.createdAt)} /></div>
          ])
        }
        isLoading={isLoading}
        emptyPlaceholderText='No payouts yet'
      />

      {
        !isLoading && <Pagination
          curPage={curPage}
          numPages={Math.ceil(totalPayoutsSize / pageSize)}
          onPageClick={page => setCurPage(page)}
        />
      }

    </div>

    {
      isCreatePayoutModalOpen &&
      <CreatePayoutModal
        visible={isCreatePayoutModalOpen}
        onClose={() => setIsCreatePayoutModalOpen(false)}
        onPayoutCreated={async (id) => {
          await loadPayouts()
          setSelectedPayoutId(id)
          setIsCreatePayoutModalOpen(false)
          setTimeout(() =>
            setIsPayoutModalOpen(true)
          , 100)
        }}
      />
    }

    {
      isPayoutModalOpen && selectedPayoutId &&
      <PayoutModal
        payoutId={selectedPayoutId}
        visible={isPayoutModalOpen}
        onPayoutConfirmed={async () => {
          await loadPayouts()
          setIsPayoutModalOpen(false)
        }}
        onClose={() => setIsPayoutModalOpen(false)} />
    }
  </Container>
})

export default Payouts
