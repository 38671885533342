import { observer } from 'mobx-react'
import React, { ReactElement, ReactNode, useEffect, useReducer, useState } from 'react'
import { Container } from '../../components/Animations'
import { PrimaryButton } from '../../components/Button'
import ConfirmButtonModal from '../../components/ConfirmButtonModal'
import Dropdown, { DropdownItem } from '../../components/Dropdown'
import PageHeader from '../../components/PageHeader'
import PaymentMethodDisplay from '../../components/PaymentMethodDisplay'
import Table from '../../components/Table'
import TimeText from '../../components/TimeText'
import Dots from '../../icons/Dots'
import Radom, { WithdrawalAccount, WithdrawalAccountDetails } from '../../state/Radom'
import { getMethod } from '../../util/Managed'
import { infoToast, successToast, useTestMode } from '../../util/Util'
import CreateAccountModal from './CreateAccountModal'
import FiatVerificationModal from './FiatVerificationModal'

export const formatAccountType = (accountDetails: WithdrawalAccountDetails): ReactNode => {
  if (accountDetails.Crypto) {
    return <PaymentMethodDisplay method={{ network: accountDetails.Crypto.network }} />
  }

  if (accountDetails.Fiat) {
    if (accountDetails.Fiat.accountType.iban) {
      return 'Fiat / IBAN'
    }
  }

  if (accountDetails.Fiat) {
    if (accountDetails.Fiat.accountType.us) {
      return 'Fiat / US'
    }
  }

  return ''
}

export const formatAccount = (accountDetails: WithdrawalAccountDetails): string => {
  if (accountDetails.Crypto) {
    return accountDetails.Crypto.address
  }

  if (accountDetails.Fiat) {
    if (accountDetails.Fiat.accountType.iban) {
      return accountDetails.Fiat.accountType.iban.iban
    }
  }

  if (accountDetails.Fiat) {
    if (accountDetails.Fiat.accountType.us) {
      return accountDetails.Fiat.accountType.us.accountNumber
    }
  }

  return ''
}

const Accounts = observer((): ReactElement => {
  const [isLoading, setIsLoading] = useState(true)
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false)
  const [isAccountVerificationModalOpen, setIsAccountVerificationModalOpen] = useState(false)

  const [withdrawalAccounts, setWithdrawalAccounts] = useState<WithdrawalAccount[]>([])

  const [testMode] = useTestMode()

  const [closeDropdownFns, updateCloseDropdownFns] = useReducer((p, n) => ({ ...p, ...n }), {})

  const fetchWithdrawalAccounts = async (): Promise<void> => {
    setIsLoading(true)
    try {
      const accounts = await Radom.listWithdrawalAccounts()
      setWithdrawalAccounts(accounts)
    } catch (err) {

    }
    setIsLoading(false)
  }

  useEffect(() => {
    fetchWithdrawalAccounts()
  }, [])

  const openAccountVerificationModal = (): void => {
    setIsCreateModalOpen(false)
    setIsAccountVerificationModalOpen(true)
  }

  return <Container>
    <div style={{ flexGrow: 1, display: 'flex', flexDirection: 'column', rowGap: 20 }}>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <PageHeader title='Accounts' subTitle='Manage your withdrawal accounts' />
        <PrimaryButton style={{ gap: 8 }} onClick={() => setIsCreateModalOpen(true)}>
          <span style={{ fontSize: 20, marginTop: -3 }}>+</span>
          <span>Create account</span>
        </PrimaryButton>
      </div>

      <Table
        borderless
        headers={['Account', 'Type', 'Details', 'Created at', ''].map(t => <span key={t}>{t}</span>)}
        items={
          withdrawalAccounts
            .filter(t => testMode
              ? getMethod(t.accountDetails.Crypto?.network ?? '').isTestnet
              : !getMethod(t.accountDetails.Crypto?.network ?? '').isTestnet
            ).map(a => [
              <div key={0} style={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
                <span>{a.name}</span>
                <span style={{ opacity: 0.5, fontSize: 14 }}>{a.id}</span>
              </div>,
              <div key={2}>
                {formatAccountType(a.accountDetails)}
              </div>,
              <div key={3}>
                {formatAccount(a.accountDetails)}
              </div>,
              <TimeText key="txTime" date={new Date(a.createdAt)} />,
              <div key={a.id} onClick={e => e.stopPropagation()}>

                <Dropdown
                  noChevron
                  onCloseFn={f => updateCloseDropdownFns({ [a.id]: f })}
                  selectedContent={<Dots width={15} />}
                  dropdownContent={
                    <div style={{ fontSize: 14, whiteSpace: 'nowrap' }}>
                      <DropdownItem onClick={() => {
                        navigator.clipboard.writeText(a.id)
                        infoToast('Account ID copied to clipboard')
                        closeDropdownFns[a.id]()
                      }}>Copy account ID</DropdownItem>
                      <ConfirmButtonModal
                        title='Delete account'
                        description='Are you sure you want to delete this account?'
                        action='Delete account'
                        onConfirm={async () => {
                          Radom.deleteWithdrawalAccount(a.id)
                            .then(async () => await fetchWithdrawalAccounts())
                            .then(() => successToast('Account deleted'))
                        }}
                        ActionComponent={props => <DropdownItem {...props}>Delete account</DropdownItem>}
                      />
                    </div>

                  }
                />
              </div>
            ])
        }
        emptyPlaceholderText='No accounts'
        isLoading={isLoading} />

      {
        isCreateModalOpen &&
      <CreateAccountModal
        title='Create withdrawal account'
        visible={isCreateModalOpen}
        onClose={() => setIsCreateModalOpen(false)}
        openAccountVerificationModal={openAccountVerificationModal}
        onAccountCreated={() => {
          setIsCreateModalOpen(false)
          fetchWithdrawalAccounts()
        }} />
      }

      <FiatVerificationModal
        title='Onboard with Bridge'
        visible={isAccountVerificationModalOpen}
        onClose={() => {
          setIsAccountVerificationModalOpen(false)
          setIsCreateModalOpen(false)
        }}
      />

    </div>
  </Container>
})

export default Accounts
