import { addDays, addMonths, addYears, format, getDate, getDay, getDaysInMonth, getMonth, getYear, monthsInYear, startOfMonth, startOfWeek, subMonths, subYears } from 'date-fns'
import { enUS } from 'date-fns/locale'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import styled from 'styled-components'

import { Calendar as CalendarIcon } from '../icons/Calendar'
import { Chevron } from '../icons/Chevron'
import { RADOM_COLORS } from '../util/Constants'
import { NewDropdown } from './Dropdown'

const MAX_YEAR = 2099
const MIN_YEAR = 1900

const Calendar = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  cursor: default;
`

const CalendarHeader = styled.div`
  font-size: 14px;
  margin-bottom: 20px;
  text-align: center;
`

const DayGrid = styled.div`
  display: grid;
  align-items: center;
  row-gap: 6px;
  justify-content: center;
  grid-template-columns: repeat(7, 1fr);
  width: 300px;
`

const MonthGrid = styled.div`
  display: grid;
  align-items: center;
  row-gap: 6px;
  justify-content: center;
  grid-template-columns: repeat(3, 1fr);
  width: 300px;
`

const YearGrid = styled.div`
  display: grid;
  align-items: center;
  row-gap: 6px;
  justify-content: center;
  grid-template-columns: repeat(3, 1fr);
  height: 200px;
  width: 300px;
  overflow: auto;
`

const Arrow = styled(Chevron)`
  width: 10px;
  height: 10px;
  padding: 10px;
  cursor: pointer;
  border-radius: 100%;

  :hover {
    background-color: ${RADOM_COLORS.GRAY_MED};
  }
`

const GridItem = styled.div`
  width: 30px;
  height: 30px;
  line-height: 30px;
`

const Day = styled(GridItem)<{ disabled?: boolean, selected?: boolean }>`
  text-align: center;
  border-radius: 100%;

  :hover {
    background-color: ${({ selected, disabled }) => selected ? RADOM_COLORS.NEW_BLUE : disabled ? 'transparent' : RADOM_COLORS.GRAY_MED}
  }

  ${({ selected }) => selected && `background-color: ${RADOM_COLORS.NEW_BLUE};`}
  ${({ selected }) => selected && 'color: white;'}
  ${({ disabled }) => !disabled && 'cursor: pointer;'}
  ${({ disabled }) => disabled && 'opacity: 40%;'}
`

const Month = styled(GridItem)<{ selected?: boolean }>`
  height: 40px;
  width: auto;
  line-height: 40px;
  text-align: center;
  cursor: pointer;

  :hover {
    background-color: ${RADOM_COLORS.GRAY_MED};
  }

  ${({ selected }) => selected && `background-color: ${RADOM_COLORS.NEW_BLUE};`}
  ${({ selected }) => selected && 'color: white;'}
`

const Year = styled(GridItem)<{ selected?: boolean }>`
  height: 30px;
  width: auto;
  line-height: 30px;
  text-align: center;
  cursor: pointer;

  :hover {
    background-color: ${RADOM_COLORS.GRAY_MED};
  }

  ${({ selected }) => selected && `background-color: ${RADOM_COLORS.NEW_BLUE};`}
  ${({ selected }) => selected && 'color: white;'}
`

interface IProps {
  value: Date | undefined
  onChange: (date: Date | undefined) => void
  placeholder?: string
  min?: Date
}

const DateSelector = (props: IProps): React.ReactElement => {
  const yearGridRef = useRef<HTMLDivElement>(null)
  const [view, setView] = useState<'day' | 'month' | 'year'>('day')
  const curDate = new Date()
  const [selectedDate, setSelectedDate] = useState<Date>(props.value ?? curDate)
  const [dateProbe, setDateProbe] = useState<Date>(props.value ?? new Date())
  const [open, setOpen] = useState(false)

  const firstDOW = startOfWeek(new Date())
  const shortWeekDaysArray = Array.from(Array(7)).map((e, i) => format(addDays(firstDOW, i), 'EEEEEE'))

  useEffect(() => {
    if (view === 'year') {
      const index = getYear(selectedDate) - MIN_YEAR
      const row = index / 3

      yearGridRef.current?.scrollTo({
        top: (row * 36) - 72
      })
    }
  }, [view, yearGridRef])

  // First day of month of curDateProbe
  const probeDay = useMemo(() => {
    return getDay(startOfMonth(dateProbe))
  }, [dateProbe])

  // Number of days in month of curDateProbe
  const probeDaysInMonth = useMemo(() => {
    return getDaysInMonth(dateProbe)
  }, [dateProbe])

  const onPrevMonth = (): void => {
    const newDate = subMonths(dateProbe, 1)
    if (
      props.min &&
      newDate.getMonth() < props.min.getMonth() &&
      newDate.getFullYear() <= props.min.getFullYear()
    ) return

    setDateProbe(newDate)
    setSelectedDate(newDate)
    props.onChange(newDate)
  }

  const onNextMonth = (): void => {
    const newDate = addMonths(dateProbe, 1)
    setDateProbe(newDate)
    setSelectedDate(newDate)
    props.onChange(newDate)
  }

  const onPrevYear = (): void => {
    const newDate = subYears(dateProbe, 1)
    if (
      props.min &&
      newDate.getFullYear() <= props.min.getFullYear()
    ) return

    setDateProbe(newDate)
    setSelectedDate(newDate)
    props.onChange(newDate)
  }

  const onNextYear = (): void => {
    const newDate = addYears(dateProbe, 1)
    setDateProbe(newDate)
    setSelectedDate(newDate)
    props.onChange(newDate)
  }

  const dayIsDisabled = (date: number): boolean => {
    const day = new Date(dateProbe.getFullYear(), dateProbe.getMonth(), date + 2)
    if (!props.min) return false
    return day < props.min
  }

  const renderDayView = (): React.ReactElement => (
    <>
      <CalendarHeader>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Arrow
            onClick={onPrevMonth}
            style={{ transform: 'rotate(90deg)' }}
          />
          <div style={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
            <span
              style={{ cursor: 'pointer', fontSize: '16px' }}
              onClick={() => setView('month')}
            >
              {format(dateProbe, 'MMMM')}
            </span>
            <span style={{ opacity: '30%' }}>{format(dateProbe, 'yyyy')}</span>
          </div>
          <Arrow
            onClick={onNextMonth}
            style={{ transform: 'rotate(270deg)' }}
          />
        </div>
      </CalendarHeader>
      <DayGrid>
        {
          shortWeekDaysArray.map(d => {
            return <div key={d} style={{ textAlign: 'center', width: '30px', height: '30px' }}>{d}</div>
          })
        }
        {
          Array.from({ length: probeDay }, (_, i) => <GridItem key={i} />)
        }
        {
          Array.from({ length: probeDaysInMonth }, (_, i) => {
            return <Day
              key={i}
              disabled={dayIsDisabled(i)}
              selected={getDate(selectedDate) === (i + 1)}
              onClick={() => {
                const newDate = new Date(
                  dateProbe.getFullYear(),
                  dateProbe.getMonth(),
                  i + 1
                )
                setSelectedDate(newDate)
                props.onChange(newDate)
                setOpen(false)
              }}
            >
              {i + 1}
            </Day>
          })
        }
      </DayGrid>
    </>
  )

  const renderMonthView = (): React.ReactElement => (
    <>
      <CalendarHeader>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Arrow
            onClick={onPrevYear}
            style={{ transform: 'rotate(90deg)' }}
          />
          <div style={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
            <span
              style={{ cursor: 'pointer', fontSize: '16px' }}
              onClick={() => setView('year')}
            >
              {format(dateProbe, 'yyyy')}
            </span>
          </div>
          <Arrow
            onClick={onNextYear}
            style={{ transform: 'rotate(270deg)' }}
          />
        </div>
      </CalendarHeader>
      <MonthGrid>
        {
          Array.from({ length: monthsInYear }, (_, i) => {
            return <Month
              key={i}
              selected={getMonth(selectedDate) === (i + 1)}
              onClick={() => {
                const newDate = new Date(dateProbe.getFullYear(), i, 1)
                setDateProbe(newDate)
                setSelectedDate(newDate)
                props.onChange(newDate)
                setView('day')
              }}
            >
              {enUS.localize?.month(i)}
            </Month>
          })
        }
      </MonthGrid>
    </>
  )

  const renderYearView = (): React.ReactElement => (
    <YearGrid ref={yearGridRef}>
      {
        Array.from({ length: MAX_YEAR - MIN_YEAR }, (_, i) => {
          return <Year
            key={i}
            selected={(getYear(selectedDate) - MIN_YEAR) === i}
            onClick={() => {
              const newDate = new Date(MIN_YEAR + i, 1, 1)
              setDateProbe(newDate)
              setSelectedDate(newDate)
              props.onChange(newDate)
              setView('month')
            }}
          >
            {MIN_YEAR + i}
          </Year>
        })
      }
    </YearGrid>
  )

  return <div style={{ width: 'fit-content' }}>
    <NewDropdown
      open={open}
      selectedContent={
        <div style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
          <CalendarIcon style={{ height: 18 }} stroke={RADOM_COLORS.GRAY_DARKER} />
          {props.value && <div>{format(selectedDate, 'MM/dd/yyyy')}</div>}
          {!props.value && props.placeholder}
          {!props.value && !props.placeholder && <span style={{ whiteSpace: 'nowrap' }}>Select date</span>}
        </div>
      }
      dropdownContent={
        <Calendar>
          {view === 'day' && renderDayView()}
          {view === 'month' && renderMonthView()}
          {view === 'year' && renderYearView()}
        </Calendar>
      }
      onClick={() => setOpen((prev) => !prev)}
      onClickOutside={() => setOpen(false)}
    />
  </div>
}

export default DateSelector
