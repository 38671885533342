import { observer } from 'mobx-react'
import React, { ReactElement, useEffect, useRef, useState } from 'react'

import Modal, { ModalProps } from '../../components/Modal'
import Spinner from '../../components/Spinner'
import Radom, { BridgeCustomer } from '../../state/Radom'
import User from '../../state/User'
import { RADOM_COLORS } from '../../util/Constants'

const FiatVerificationModal = observer((props: ModalProps): ReactElement => {
  const [bridgeCustomer, setBridgeCustomer] = useState<BridgeCustomer | null>(null)

  const iframeRef = useRef(null)

  useEffect(() => {
    if (props.visible) {
      Radom.createBridgeCustomer().then((bridgeCustomer) => {
        setBridgeCustomer(bridgeCustomer)
      })
    }
  }, [props.visible])

  const renderContent = (): ReactElement => {
    if (!User.organization?.bridgeCustomer) {
      return (
        <div style={{ display: 'flex', justifyContent: 'center', padding: '20px' }}>
          <Spinner />
        </div>
      )
    }

    if (User.organization.bridgeCustomer.status === 'Pending') {
      return (
        <div style={{
          padding: 30,
          borderRadius: 15,
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0
        }}>
          <div style={{
            border: `1px solid ${RADOM_COLORS.GRAY_DARK}`,
            backgroundColor: RADOM_COLORS.GRAY_LIGHTEST,
            fontSize: 14,
            padding: 20,
            borderRadius: 10,
            display: 'flex',
            flexDirection: 'column',
            gap: 5
          }}>
            <div style={{ display: 'flex', gap: 30, alignItems: 'center' }}>
              <div style={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
                <span style={{ fontWeight: 500, fontSize: 15 }}>Your account verification is pending</span>
                <span style={{ opacity: 0.5 }}>
              Bridge is currently verifying your account.
              Once the verification is complete, Fiat account creation will be enabled.
                </span>
              </div>
            </div>
          </div>
        </div>
      )
    }

    if (User.organization.bridgeCustomer.status === 'Accepted') {
      return (
        <div style={{
          padding: '0 30px 30px 30px',
          borderRadius: 15,
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0
        }}>
          <div style={{
            border: `1px solid ${RADOM_COLORS.GRAY_DARK}`,
            backgroundColor: RADOM_COLORS.GRAY_LIGHTEST,
            fontSize: 14,
            padding: 20,
            borderRadius: 10,
            display: 'flex',
            flexDirection: 'column',
            gap: 5
          }}>
            <div style={{ display: 'flex', gap: 30, alignItems: 'center' }}>
              <div style={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
                <span style={{ fontWeight: 500, fontSize: 15 }}>Your account verification is verified</span>
                <span style={{ opacity: 0.5 }}>
              Your account has been verified by Bridge. You can now create Fiat accounts.
                </span>
              </div>
            </div>
          </div>
        </div>
      )
    }

    return !!bridgeCustomer && bridgeCustomer.status === 'Created'
      ? (
        <iframe
          ref={iframeRef}
          style={{ width: '100%', height: 350, border: 0 }}
          onLoad={async () => {
            try {
              const path = (iframeRef.current as any).contentWindow?.location?.href
              if (path.startsWith('http://localhost:1234')) {
                props.onClose()
                await Radom.updateBridgeCustomer(bridgeCustomer.id, {
                  status: 'TosApproved'
                })
              }
            } catch (err) {

            }
          }}
          src={`${bridgeCustomer.tosLink}&redirect_uri=http://localhost:1234`}
        />
      )
      : <></>
  }

  return <Modal {...props} innerStyles={{ width: 550 }}>
    {renderContent()}
  </Modal>
})

export default FiatVerificationModal
