import React from 'react'
import styled from 'styled-components'
import AnimatedCheckmark from '../icons/AnimatedCheckmark'
import Upload from '../icons/Upload'
import { RADOM_COLORS } from '../util/Constants'

const ImageUploadButton = styled.div<{ value?: File }>`
  cursor: pointer;
  border: 1px solid ${RADOM_COLORS.GRAY4};
  border-radius: 10px;
  width: 70px;
  height: 70px;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${RADOM_COLORS.GRAY_DARKER};
  transition: 0.2s ease all;
  position: relative;
  overflow: hidden;
  background-size: cover;

  :hover {
    border-color: ${RADOM_COLORS.GRAY_DARK};
  }

  :active {
    opacity: 0.5;
  }

  ${({ value }) => !value && `
    border-style: dashed;
  `}
`

interface IProps {
  disabled?: boolean
  value?: File
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
}

const FileInput = ({ disabled = false, value, onChange }: IProps): React.ReactElement => (
  <ImageUploadButton value={value}>
    {
      !value && <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          pointerEvents: 'none',
          gap: 2,
          color: RADOM_COLORS.BLACK
        }}
      >
        <Upload style={{ height: 25 }} />
        <span style={{ fontSize: 10 }}>Upload</span>
      </div>
    }
    <input
      disabled={disabled}
      type="file"
      name="profile_picture"
      onChange={onChange}
      style={{
        opacity: 0,
        cursor: 'pointer',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0
      }}
    />
    {
      value && <AnimatedCheckmark style={{ color: RADOM_COLORS.NICE_GREEN }} />
    }
  </ImageUploadButton>
)

export default FileInput
