import { observer } from 'mobx-react'
import React, { ReactElement, ReactNode, useState } from 'react'
import Modal, { ModalProps } from '../../components/Modal'
import { errorToast, useTestMode } from '../../util/Util'
import { PrimaryButton, SecondaryButton } from '../../components/Button'
import { ManagedPaymentMethodSelector } from '../../components/ManagedMethodMultiselector'
import { ManagedPaymentMethod } from '../../util/Managed'
import { TextInput } from '../../components/Input'
import NumberInput from '../../components/NumberInput'
import PaymentMethodDisplay from '../../components/PaymentMethodDisplay'
import { RADOM_COLORS } from '../../util/Constants'
import Upload from '../../icons/Upload'
import Radom from '../../state/Radom'

interface CreatePayoutModalProps extends ModalProps {
  onPayoutCreated: (id: string) => void
}

interface PayoutEntry {
  network: string
  token?: string
  amount?: string
  payoutAddress?: string
}

const CreatePayoutModal = observer(({
  onPayoutCreated,
  ...props
}: CreatePayoutModalProps): ReactElement => {
  const [isLoading, setIsLoading] = useState(false)

  const [testMode] = useTestMode()

  const [payoutEntries, setPayoutEntries] = useState<PayoutEntry[]>([])
  const [selectedMethod, setSelectedMethod] = useState<ManagedPaymentMethod>()
  const [amount, setAmount] = useState<string>()
  const [payoutAddress, setPayoutAddress] = useState<string>()

  const createPayout = async (): Promise<void> => {
    setIsLoading(true)
    try {
      const payout = await Radom.createPayout({
        payouts: payoutEntries.map(p => ({
          crypto: {
            ...p
          }
        }))
      })
      onPayoutCreated(payout.id)
    } catch (err) {
      errorToast(err.response?.data?.error || err.message)
    }
    setIsLoading(false)
  }

  const createPayoutForm = (): ReactNode => {
    return <div style={{
      display: 'grid',
      gridTemplateColumns: 'minmax(130px, auto) min-content 1fr min-content',
      alignItems: 'center',
      columnGap: 20,
      rowGap: 10,
      borderRadius: 5,
      marginTop: 10
    }}>
      <ManagedPaymentMethodSelector
        hideDisabled
        disabled={isLoading}
        hideLabel
        placeholderText='Select asset'
        selectedMethods={selectedMethod ? [selectedMethod] : []}
        setSelectedMethods={(methods) => setSelectedMethod(methods[methods.length - 1])}
        allowedNetwork={
          payoutEntries.length > 0
            ? payoutEntries[0].network
            : undefined
        }
        testnetOnly={testMode} />

      <NumberInput
        disabled={isLoading}
        style={{ minWidth: 100 }}
        value={amount}
        onUserInput={(v) => {
          setAmount(v)
        }} />

      <TextInput
        disabled={isLoading}
        type="text"
        placeholder='Enter payout address'
        value={payoutAddress}
        onChange={(e) => {
          setPayoutAddress(e.target.value)
        }}
      />

      <PrimaryButton
        disabled={isLoading}
        style={{ fontSize: 14, padding: '8px 20px' }}
        onClick={() => {
          if (!selectedMethod || !amount || !payoutAddress) {
            return
          }

          const newPayouts = [] as any[]
          for (let i = 0; i < 1; i++) {
            newPayouts.push({
              network: selectedMethod?.hostChain?.name ?? selectedMethod?.name,
              token: selectedMethod?.hostChain?.tokenAddress,
              amount,
              payoutAddress
            })
          }

          setPayoutEntries([
            ...payoutEntries,
            ...newPayouts

          ])

          setSelectedMethod(undefined)
          setAmount('')
          setPayoutAddress('')
        }}>
          Add
      </PrimaryButton>

    </div>
  }

  return <Modal title='Create payout' {...props} innerStyles={{ maxWidth: 750 }}>
    <div style={{
      display: 'flex',
      flexGrow: 1,
      flexDirection: 'column',
      justifyContent: 'start',
      gap: 10,
      padding: '0px 30px',
      fontSize: 14
    }}>
      <div style={{
        display: 'grid',
        gridTemplateColumns: 'minmax(130px, auto) 60px 1fr min-content',
        alignItems: 'center',
        columnGap: 20,
        rowGap: 10,
        padding: '0 20px'
      }}>
        <div style={{ textTransform: 'uppercase', opacity: 0.5, fontSize: 12 }}>Asset</div>
        <div style={{ textTransform: 'uppercase', opacity: 0.5, fontSize: 12 }}>Amount</div>
        <div style={{ textTransform: 'uppercase', opacity: 0.5, fontSize: 12 }}>Payout address</div>
        <div></div>
      </div>

      <div style={{
        display: 'grid',
        gridTemplateColumns: 'minmax(130px, auto) 60px 1fr min-content',
        alignItems: 'center',
        columnGap: 20,
        rowGap: 10,
        maxHeight: '50vh',
        overflow: 'scroll',
        borderRadius: 15,
        padding: 20,
        border: `1px solid ${RADOM_COLORS.GRAY9}`
      }}>
        <div style={{ display: 'contents', overflow: 'hidden' }}>
          {
            payoutEntries.length === 0 &&
            <div style={{
              gridColumn: 'span 4',
              padding: 15,
              borderRadius: 15,
              color: 'rgba(0, 0, 0, 0.75)',
              textAlign: 'center',
              opacity: 0.5
            }}>No payout entries</div>
          }
          {
            payoutEntries.map((p, i) => <>
              <div key={`${i}-m`}>
                <PaymentMethodDisplay
                  disableTooltip={false}
                  method={{ network: p.network, token: p.token }} />
              </div>
              <div key={`${i}-a`}>{p.amount}</div>
              <div key={`${i}-aa`} style={{
                textOverflow: 'ellipsis',
                overflow: 'auto',
                whiteSpace: 'pre'
              }}>{p.payoutAddress}</div>
              <div key={`${i}-e`}>

                <PrimaryButton
                  disabled={isLoading}
                  style={{
                    backgroundColor: RADOM_COLORS.GRAY8,
                    fontSize: 14,
                    color: RADOM_COLORS.GRAY_DARKEST,
                    padding: '8px 20px'
                  }}
                  onClick={() => {
                    setPayoutEntries(payoutEntries.filter(pp => pp !== p))
                  }}>
                    Remove
                </PrimaryButton>

              </div>
            </>)
          }
        </div>

      </div>

      {createPayoutForm()}
    </div>

    <div style={{
      padding: 30,
      paddingTop: 20,
      display: 'flex',
      flexDirection: 'column',
      gap: 15
    }}>

      <PrimaryButton
        disabled={payoutEntries.length <= 0}
        isLoading={isLoading}
        style={{ width: '100%' }}
        onClick={() => {
          createPayout()
        }}>
          Create payout
      </PrimaryButton>

      {
        false &&
        <SecondaryButton
          disabled={isLoading}
          style={{
            fontSize: 14,
            width: '100%',
            backgroundColor: 'transparent',
            border: 'none',
            color: RADOM_COLORS.GRAY_DARKEST,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: 0,
            gap: 5
          }}
          onClick={() => {

          }}>
          <span>Import CSV</span>
          <Upload stroke={RADOM_COLORS.GRAY_DARKEST} style={{ width: 18, height: 18 }} />
        </SecondaryButton>
      }
    </div>

  </Modal>
})

export default CreatePayoutModal
