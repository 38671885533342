import { GoogleOAuthProvider } from '@react-oauth/google'
import { formatRelative } from 'date-fns'
import { observer } from 'mobx-react'
import React, { useEffect, useState } from 'react'
import { Toaster } from 'react-hot-toast'
import { Link, Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import styled, { createGlobalStyle, keyframes } from 'styled-components'
import { Container } from './components/Animations'
import { PrimaryButton, SecondaryButton } from './components/Button'
import ConfirmButtonModal from './components/ConfirmButtonModal'
import Dropdown from './components/Dropdown'
import { InputLabel, TextInput } from './components/Input'
import InviteAcceptModal from './components/InviteAcceptModal'
import LinkExternal from './components/LinkExternal'
import LoadingBar from './components/LoadingBar'
import Modal from './components/Modal'
import SearchBox from './components/SearchBox'
import Switch from './components/Switch'
import Tooltip from './components/Tooltip'
import WithAuthentication from './components/WithAuthentication'
import { Chevron } from './icons/Chevron'
import MeteredCharge from './icons/MeteredCharge'
import AccountIcon from './icons/nav/Account'
import BillingIcon from './icons/nav/Billing'
import CheckoutSessionsIcon from './icons/nav/CheckoutSessions'
import CustomersIcon from './icons/nav/Customers'
import DevelopersIcon from './icons/nav/Developers'
import DiscountCodesIcon from './icons/nav/DiscountCodes'
import DonationLinksIcon from './icons/nav/Donations'
import FundsIcon from './icons/nav/Funds'
import HomeIcon from './icons/nav/Home'
import IntegrationsIcon from './icons/nav/Integrations'
import InvoicesIcon from './icons/nav/Invoices'
import PaymentLinksIcon from './icons/nav/PaymentLinks'
import PaymentsIcon from './icons/nav/Payments'
import PresalesIcon from './icons/nav/Presales'
import ProductsIcon from './icons/nav/Products'
import SettingsIcon from './icons/nav/Settings'
import TeamIcon from './icons/nav/Team'
import NewSubscription from './icons/NewSubscription'
import { NotificationBell } from './icons/NotificationBell'
import OrderIcon from './icons/Order'
import RadomLogo from './icons/Radom'
import Refund from './icons/Refund'
import RoleAssigned from './icons/RoleAssigned'
import RoleCreated from './icons/RoleCreated'
import RoleDeleted from './icons/RoleDeleted'
import RoleUnassigned from './icons/RoleUnassigned'
import RoleUpdated from './icons/RoleUpdated'
import SignOut from './icons/SignOut'
import SubscriptionRevoked from './icons/SubscriptionRevoked'
import Withdrawal from './icons/Withdrawal'
import DefaultSeller from './images/DefaultSeller.png'
import EventsState from './state/Events'
import NavbarController from './state/NavbarController'
import Radom, { IOrganization } from './state/Radom'
import Treasury from './state/Treasury'
import User from './state/User'
import { RADOM_COLORS } from './util/Constants'
import { errorToast, useTestMode } from './util/Util'
import Account from './views/Account'
import Billing from './views/Billing'
import Subscriptions from './views/Billing/Subscriptions'
import CheckoutSession from './views/CheckoutSession'
import CheckoutSessions from './views/CheckoutSessions'
import Customers from './views/Customers'
import CustomerView from './views/Customers/Customer'
import APITokens from './views/Developers/APITokens'
import Developers from './views/Developers/Developers'
import TestnetTokens from './views/Developers/TestnetTokens'
import ViewWebhook from './views/Developers/ViewWebhook'
import Webhooks from './views/Developers/Webhooks'
import DiscountCodes from './views/DiscountCodes'
import DonationLinks from './views/DonationLinks'
import CreateDonationLink from './views/DonationLinks/CreateDonationLink'
import EditDonationLink from './views/DonationLinks/EditDonationLink'
import Funds from './views/Funds'
import Accounts from './views/Funds/Accounts'
import AutomationView from './views/Funds/Automation'
import ManagedWithdrawals from './views/Funds/ManagedWithdrawals'
import Payouts from './views/Funds/Payouts'
import Home from './views/Home'
import Integrations from './views/Integrations'
import Invoices from './views/Invoices'
import CreateInvoice from './views/Invoices/CreateInvoice'
import ViewInvoice from './views/Invoices/ViewInvoice'
import NotFound from './views/NotFound'
import CurrenciesSettings from './views/Organization/CurrenciesSettings'
import Notifications from './views/Organization/Notifications'
import Profile from './views/Organization/Overview'
import Payment from './views/Payment'
import PaymentLinks from './views/PaymentLinks'
import { CreatePaymentLink } from './views/PaymentLinks/CreatePaymentLink'
import EditPaymentLink from './views/PaymentLinks/EditPaymentLink'
import { CreatePresaleLink } from './views/Presale/CreatePresaleLink'
import EditPresaleLink from './views/Presale/EditPresaleLink'
import Presales from './views/Presales'
import Products from './views/Products'
import { CreateDiscount } from './views/Products/CreateDiscountCode'
import CreateProduct from './views/Products/CreateProduct'
import EditProduct from './views/Products/EditProduct'
import Subscription from './views/Subscription'
import Team from './views/Team'
import CreateAPIRole from './views/Team/CreateAPIRole'
import TeamRoles from './views/TeamRoles'
import Verify from './views/Verify'

const GlobalStyles = createGlobalStyle<{ isModalOpen: boolean }>`
  body {
    ${({ isModalOpen }) => isModalOpen && 'overflow: hidden;'}
  }
`

const Notification = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 20px;
  border-bottom: 1px solid ${RADOM_COLORS.GRAY_LIGHTEST};
  transition: 0.2s ease all;
  cursor: pointer;

  :hover {
    background-color: ${RADOM_COLORS.GRAY_LIGHTEST};
  }

  :active {
    opacity: 0.75;
  }
`

const SlideIn = keyframes`
  from {
    margin-top: -50px;
    opacity: 0;
  }
  to {
    margin-top: 0px;
    opacity: 1;
  }
`

const TestModeWrapper = styled.div`
  position: sticky;
  border-top: 1px solid ${RADOM_COLORS.ORANGE};
  display: flex;
  justify-content: center;
  animation: ${SlideIn} 0.2s ease;
  z-index: 1;
  top: 54px;
  pointer-events: none;
  user-select: none;
`

const TestModeLabel = styled.span`
  background: ${RADOM_COLORS.ORANGE};
  color: white;
  font-size: 10px;
  font-weight: 600;
  padding: 4px 12px;
  text-transform: uppercase;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
`

const RadomNavContainer = styled.div<{ isFullscreen: boolean }>`
  position: fixed;
  top: 0;
  flex-direction: column;
  height: fit-content;
  z-index: 3;
  top: 0;
  transition: 0.5s ease all;
  height: 100vh;
  justify-content: flex-start;
  box-shadow: 1px 0 1px ${RADOM_COLORS.GRAY_MED};
`

const RadomNav = styled.div<{ isFullscreen: boolean }>`
  transition: 0.2s ease all;
  background: linear-gradient(to left, #F8F8FB, white);
  display: flex;
  align-items: flex-start;
  gap: 20px;
  // border-bottom: 1px solid ${RADOM_COLORS.GRAY_MED};
  height: 100vh;
  margin: auto;
  width: 230px;
  margin-left: 0px;
  ${({ isFullscreen }) => isFullscreen && 'margin-left: -230px;'}
`

const NavRadomLogo = styled(Link)`
  cursor: pointer;
  height: 100%;
  display: flex;
  align-items: center;
  text-decoration: none;
  
  :hover {
    opacity: 0.9;
  }

  :active {
    opacity: 1;
  }
`

const ExternalLink = styled.a<{ $$active: boolean }>`
  display: flex;
  align-items: center;
  cursor: pointer;
  height: 100%;
  font-size: 14px;
  color: ${RADOM_COLORS.GRAY_DARKEST};
  transition: 0.2s ease all;
  padding: 0;
  user-select: none;
  text-decoration: none;
  white-space: pre;
  ${({ $$active }) => $$active && 'color: black;'}
  
  svg {
    transition: 0.2s ease all;
  }
  
  :hover {
    opacity: 1;
    ${({ $$active }) => !$$active && 'color: black;'}

    svg {
      stroke: black;
    }
  }

  :active {
    opacity: 0.5;
  }
`

const NavItem = styled(Link)<{ $$active: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  height: 100%;
  font-size: 14px;
  color: ${RADOM_COLORS.GRAY_DARKEST};
  padding: 0;
  user-select: none;
  text-decoration: none;
  white-space: pre;
  ${({ $$active }) => $$active && 'color: white;'}
  ${({ $$active }) => $$active && `
    svg {
      stroke: black;
    }
  `}
  
  svg {
    transition: 0.2s ease all;
  }
  
  :hover {
    opacity: 1;
    ${({ $$active }) => !$$active && 'color: black;'}


    svg {
      stroke: black;
    }
  }

  :active {
    opacity: 0.5;
  }
`

const NavItemGroup = styled.div<{ $$active: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  height: 100%;
  font-size: 14px;
  color: ${RADOM_COLORS.GRAY_DARKEST};
  padding: 0;
  user-select: none;
  text-decoration: none;
  white-space: pre;
  
  svg {
    transition: 0.2s ease all;
  }

  ${({ $$active }) => $$active && `
    svg {
      stroke: black;
    }
  `}
  
  :hover {
    opacity: 1;
    ${({ $$active }) => !$$active && 'color: black;'}

    svg {
      stroke: black;
    }
  }

  :active {
    ${({ $$active }) => !$$active && 'opacity: 0.5;'}
  }
`

const NavItemGroupContainer = styled.div<{ $active: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  transform-origin: 0 0;
  transition: 0.3s ease all;
  overflow: hidden;
  gap: 1px;

  max-height: ${({ $active }) => $active ? '200px' : '0'};
`

const NavItemText = styled.div<{ $active: boolean }>`
  padding: 5px 15px;
  transition: 0.2s ease all;
  border-radius: 15px;
  box-sizing: border-box;
  border: 1px solid transparent;
  background: transparent;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 8px;

  ${({ $active }) => $active && `
    background: ${RADOM_COLORS.GRAY_MED};
    color: black;
    border: 1px solid #ffffff1a;
  `}

  &:hover {
    ${({ $active }) => !$active && `background: ${RADOM_COLORS.GRAY_LIGHTEST};`}
  }
`

const NavItemGroupText = styled.div<{ $active: boolean }>`
  padding: 5px 15px;
  transition: 0.2s ease all;
  border-radius: 15px;
  box-sizing: border-box;
  background: transparent;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: space-between;
  border: 1px solid transparent;
  
  ${({ $active }) => $active && `
    margin-bottom: 3px;
    color: black;
    font-weight: 600;
  `}

  &:hover {
    ${({ $active }) => !$active && `background: ${RADOM_COLORS.GRAY_LIGHTEST};`}
    
    #chevron {
      transform: scale(1) rotate(${({ $active }) => $active ? -180 : 0}deg);
      opacity: 1;
    }
  }
  
  #chevron {
    opacity: ${({ $active }) => $active ? 1 : 0};
    transform: scale(${({ $active }) => $active ? 1 : 0}) rotate(${({ $active }) => $active ? -180 : 0}deg);
    stroke: none;
  }
`

const routes = [
  {
    path: '/',
    name: 'Home',
    icon: HomeIcon,
    element: <Home />
  },
  {
    path: '/funds',
    name: 'Funds',
    icon: FundsIcon,
    element: <Funds />,
    children: [
      {
        path: '/funds',
        name: 'Balances',
        element: <Funds />
      },
      {
        path: '/withdrawals',
        name: 'Withdrawals',
        element: <ManagedWithdrawals />
      },
      {
        path: '/payouts',
        name: 'Payouts',
        element: <Payouts />
      },
      {
        path: '/accounts',
        name: 'Accounts',
        element: <Accounts />
      },
      {
        path: '/automation',
        name: 'Automation',
        element: <AutomationView />
      }
    ]
  },
  {
    path: '/withdrawals',
    element: <ManagedWithdrawals />
  },
  {
    path: '/payouts',
    element: <Payouts />
  },
  {
    path: '/accounts',
    element: <Accounts />
  },
  {
    path: '/automation',
    element: <AutomationView />
  },
  {
    path: '/invoices/create',
    element: <CreateInvoice />,
    requireAPI: true
  },
  {
    path: '/payments',
    name: 'Payments',
    icon: PaymentsIcon,
    element: <Billing />
  },
  {
    path: '/subscriptions',
    name: 'Subscriptions',
    icon: BillingIcon,
    element: <Container>
      <div style={{ display: 'flex', flexDirection: 'column', rowGap: 20, flexGrow: 1 }}>
        <Subscriptions />
      </div>
    </Container>
  },
  {
    path: '/products',
    name: 'Products',
    icon: ProductsIcon,
    element: <Products />,
    requireAPI: true
  },
  {
    path: '/discount_codes',
    name: 'Discount codes',
    icon: DiscountCodesIcon,
    element: <DiscountCodes />,
    requireAPI: true
  },
  {
    path: '/products/create',
    element: <CreateProduct />,
    requireAPI: true
  },
  {
    path: '/products/:productId',
    element: <EditProduct />,
    requireAPI: true
  },
  {
    path: '/discounts/create',
    element: <CreateDiscount />,
    requireAPI: true
  },
  {
    path: '/payment_links/create',
    element: <CreatePaymentLink />,
    requireAPI: true
  },
  {
    path: '/payment_links/:paymentLinkId',
    element: <EditPaymentLink />,
    requireAPI: true
  },
  {
    path: '/presales/create',
    element: <CreatePresaleLink />,
    requireAPI: true
  },
  {
    path: '/presales/:paymentLinkId',
    element: <EditPresaleLink />,
    requireAPI: true
  },
  {
    path: '/payment_links',
    name: 'Payment links',
    icon: PaymentLinksIcon,
    element: <PaymentLinks />,
    requireAPI: true
  },
  {
    name: 'Donation links',
    path: '/donation_links',
    icon: DonationLinksIcon,
    element: <DonationLinks />,
    requireAPI: true
  },
  {
    path: '/donation_links/create',
    element: <CreateDonationLink />,
    requireAPI: true
  },
  {
    path: '/donation_link/:donationLinkId',
    element: <EditDonationLink />,
    requireAPI: true
  },
  {
    path: '/invoices',
    element: <Invoices />,
    icon: InvoicesIcon,
    name: 'Invoices',
    requireAPI: true
  },
  {
    path: '/presales',
    name: 'Presales',
    icon: PresalesIcon,
    element: <Presales />,
    requireAPI: true,
    features: []
  },
  {
    path: '/customers',
    name: 'Customers',
    icon: CustomersIcon,
    element: <Customers />,
    requireAPI: true
  },
  {
    path: '/customer/:id',
    element: <CustomerView />,
    requireAPI: true
  },
  {
    path: '/checkout_sessions',
    name: 'Checkout sessions',
    icon: CheckoutSessionsIcon,
    element: <CheckoutSessions />,
    requireAPI: true
  },
  {
    path: '/invoices/:invoiceId',
    element: <ViewInvoice />,
    requireAPI: true
  },
  {
    path: '/team',
    name: 'Team',
    icon: TeamIcon,
    element: <Team />,
    children: [
      {
        path: '/team',
        name: 'Members',
        element: <Team />
      },
      {
        path: '/team/roles',
        name: 'Roles',
        element: <TeamRoles />
      }
    ]
  },
  {
    path: '/team/roles',
    element: <TeamRoles />
  },
  {
    path: '/role/create',
    element: <CreateAPIRole />
  },
  // {
  //   path: '/events',
  //   name: 'Events',
  //   element: <Events />
  // },
  {
    path: '/developers/webhook/:webhookId',
    element: <ViewWebhook />,
    requireAPI: true
  },
  {
    path: '/profile',
    element: <Profile />,
    requireAPI: true
  },
  {
    path: '/payment/:id',
    element: <Payment />
  },
  {
    path: '/checkout_session/:id',
    element: <CheckoutSession />
  },
  {
    name: 'Integrations',
    path: '/integrations',
    icon: IntegrationsIcon,
    element: <Integrations />
  },
  {
    path: '/developers',
    name: 'Developers',
    icon: DevelopersIcon,
    element: <Developers />,
    requireAPI: true,
    children: [
      {
        path: '/developers/webhooks',
        name: 'Webhooks',
        element: <Webhooks />
      },
      {
        path: '/developers/api_tokens',
        name: 'API tokens',
        element: <Developers />
      },
      {
        path: '/developers/testnet_tokens',
        name: 'Testnet tokens',
        element: <TestnetTokens />
      }
    ]
  },
  {
    path: '/developers/webhooks',
    element: <Webhooks />
  },
  {
    path: '/developers/api_tokens',
    element: <APITokens />
  },
  {
    path: '/developers/testnet_tokens',
    element: <TestnetTokens />
  },
  {
    path: '/subscription/:subscriptionId',
    element: <Subscription />,
    requireAPI: true
  },
  {
    path: '/settings',
    name: 'Organization',
    icon: SettingsIcon,
    element: <Profile />,
    children: [
      {
        path: '/branding',
        name: 'Branding',
        element: <Profile />
      },
      {
        path: '/settings/currencies',
        name: 'Currencies',
        element: <Profile />
      }
    ]
  },
  {
    path: '/settings/currencies',
    element: <CurrenciesSettings />
  },
  {
    path: '/branding',
    element: <Profile />
  },
  {
    path: '/notifications',
    element: <Notifications />
  },
  {
    path: '/account',
    element: <Account />
  },
  {
    path: '/account',
    name: 'Account',
    icon: AccountIcon,
    children: [
      {
        path: '/notifications',
        name: 'Notifications',
        element: <Profile />
      },
      {
        path: '/account',
        name: 'Settings',
        element: <Account />
      }
    ]
  },
  {
    path: '/verify',
    element: <Verify />
  }
]

const App = observer(() => {
  const location = useLocation()
  const navigate = useNavigate()

  const [profile, setProfile] = useState<IOrganization>()
  const [isLoadingProfile, setIsLoadingProfile] = useState(true)
  const [numNotifications, setNumNotifications] = useState(0)
  const [notificationsCloseFn, setNotificationsCloseFn] = useState(() => () => {})
  // const [navDropdownCloseFn, setNavDropdownCloseFn] = useState(() => () => {})

  const [isLoadingAckedNotifications, setIsLoadingAckedNotifications] = useState(true)
  const [latestBlockAcks, setLatestBlockAcks] = useState<{ [chainID: number]: number }>({})
  const [latestManagedAck, setLatestManagedAck] = useState(new Date(0))

  const [organizationModalVisible, setOrganizationModalVisible] = useState(false)
  const [organizations, setOrganizations] = useState([] as IOrganization[])
  const selectedOrg = organizations.find(o => o.organizationId === User.selectedOrganizationId)

  const [testMode, setTestMode] = useTestMode()

  const [impersonationModalOpen, setImpersonationModalOpen] = useState(false)
  const [impersonationOrgId, setImpersonationOrgId] = useState('')

  const [activeNavGroup, setActiveNavGroup] = useState<string>()

  const loadUserProfile = async(): Promise<void> => {
    setIsLoadingProfile(true)
    Radom.getSellerProfile()
      .then(p => p && setProfile(p))
      .catch((err) => { console.error('Failed to load seller profile', err) })
      .finally(() => setIsLoadingProfile(false))
  }

  const loadOrganizations = async(): Promise<void> => {
    try {
      const orgIds = await Radom.listOrganizations()
      if (User.selectedOrganizationId && !orgIds.includes(User.selectedOrganizationId)) {
        orgIds.push(User.selectedOrganizationId)
      }

      await User.getOrganization()

      const orgs = await Promise.all(orgIds.sort()
        .map(async o => await Radom.getOrganizationById(o)))

      setOrganizations(orgs)
    } catch (err) {
      console.error('Failed to load organizations', err)
    }
  }

  const onClickNotifications = async (): Promise<void> => {
    Radom.updateLastBlockAck([
      ...Object.entries(EventsState.latestBlockScanned).map(([chainID, latestBlock]) =>
        ({ chainId: Number(chainID), lastBlockAck: latestBlock })
      ),
      { managedLastAck: new Date().toISOString() }
    ]).then(() => {
      setNumNotifications(0)
    })
  }

  const loadUserNotifications = async(): Promise<void> => {
    setIsLoadingAckedNotifications(true)
    Radom.getLastBlockAck()
      .then(acks => {
        const latestAcks = {}
        for (const ack of acks) {
          if (ack.chainId && ack.lastBlockAck) {
            latestAcks[ack.chainId] = ack.lastBlockAck
          } else if (ack.managedLastAck) {
            setLatestManagedAck(new Date(ack.managedLastAck))
          }
        }
        setLatestBlockAcks(latestAcks)
      })
      .catch(() => {})
      .finally(() => {
        setIsLoadingAckedNotifications(false)
      })
  }

  useEffect(() => {
    if (User.organization && User.organization.verificationStatus !== 'Verified') {
      setTestMode(true)
    }
  }, [User.organization])

  useEffect(() => {
    if (Radom.sellerProfile) setProfile(Radom.sellerProfile)
  }, [Radom.sellerProfile])

  useEffect(() => {
    if (isLoadingAckedNotifications) return
    setNumNotifications(EventsState.managedEvents.filter(e => e.date.getTime() > latestManagedAck.getTime()).length)
  }, [EventsState.allEvents, latestBlockAcks])

  useEffect(() => {
    if (!Radom.isLoggedIn) {
      return
    }

    EventsState.loadEvents(testMode)
    Treasury.loadBalances()
    loadUserProfile()
    loadUserNotifications()
    loadOrganizations()

    const loadHubSpotScript = async (): Promise<void> => {
      return await new Promise<void>((resolve, reject) => {
        const scripts = document.body.querySelectorAll('script')
        const scriptAlreadyAdded = Array.from(scripts).some(s => s.src === 'https://js.hs-scripts.com/25545658.js')
        if (scriptAlreadyAdded) {
          return resolve()
        }

        const script = document.createElement('script')
        script.src = 'https://js.hs-scripts.com/25545658.js'
        script.async = true
        script.onload = () => resolve()
        script.onerror = () => reject(new Error('Failed to load HubSpot script'))
        document.body.appendChild(script)
      })
    }

    const initHubSpot = async (): Promise<void> => {
      try {
        const user = await User.getUser()
        if (user?.emailAddress) {
          const visitorToken = localStorage.getItem('visitorToken')
          window.hsConversationsSettings = {
            identificationEmail: user.emailAddress,
            identificationToken: visitorToken ?? ''
          }
        }

        await loadHubSpotScript()
        if (window.HubSpotConversations?.widget) {
          window.HubSpotConversations.widget.load()
        } else {
          console.error('HubSpotConversations is not available on the window object.')
        }
      } catch (error) {
        console.error('Error initializing HubSpot:', error)
      }
    }

    initHubSpot()
  }, [Radom.isLoggedIn, User.selectedOrganizationId])

  return (
    <div style={{ maxWidth: NavbarController.isFullscreen || !Radom.isLoggedIn ? 'initial' : 1650, margin: 'auto' }}>
      <GlobalStyles isModalOpen={NavbarController.isModalOpen} />
      <GoogleOAuthProvider clientId="449354372531-5s4moklb4v4freib3f6q58u7f0fba7qk.apps.googleusercontent.com">
        <WithAuthentication>
          <div style={{
            display: 'flex',
            flexDirection: 'row'
          }}>

            <RadomNavContainer isFullscreen={NavbarController.isFullscreen}>
              <RadomNav isFullscreen={NavbarController.isFullscreen}>
                <div style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  width: '100%'
                }}>

                  <div style={{
                    padding: '10px 20px',
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    gap: 10
                  }}>
                    <NavRadomLogo to="/" onClick={() => setActiveNavGroup('Home')}>
                      <RadomLogo style={{ width: 70 }} />
                    </NavRadomLogo>
                  </div>

                  <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    padding: '10px',
                    width: '100%',
                    gap: 5
                  }}>
                    {routes
                      .filter(r => {
                        if (!r.features) {
                          return true
                        }

                        for (const feature of r.features) {
                          if (!selectedOrg?.features?.[feature]) {
                            return false
                          }
                        }

                        return true
                      })
                      .filter(r => r.name).map(route => {
                        if (route.children) {
                          return <NavItemGroup
                            key={route.name}
                            $$active={activeNavGroup === route.name}
                            onClick={() => {
                              if (activeNavGroup === route.name) {
                                setActiveNavGroup(undefined)
                              } else {
                                setActiveNavGroup(route.name)
                              }
                            }}>
                            <NavItemGroupText $active={activeNavGroup === route.name}>
                              <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
                                {route.icon && <route.icon style={{ height: 15 }}/> }
                                <span>{route.name}</span>
                              </div>
                              <Chevron id='chevron' />
                            </NavItemGroupText>

                            {

                              <NavItemGroupContainer $active={activeNavGroup === route.name}>

                                {
                                  route.children.map(route =>
                                    <NavItem
                                      style={{
                                        width: '100%'
                                      }}
                                      onClick={e => e.stopPropagation()}
                                      key={route.name}
                                      $$active={location.pathname === route.path || (route.path === '/' && !location.pathname)}
                                      to={route.path}>
                                      <NavItemText
                                        style={{ paddingLeft: 40 }}
                                        $active={location.pathname === route.path || (route.path === '/' && !location.pathname)}>{route.name}</NavItemText>
                                    </NavItem>
                                  )
                                }
                              </NavItemGroupContainer>
                            }
                          </NavItemGroup>
                        }

                        return <NavItem
                          key={route.name}
                          $$active={activeNavGroup === route.name}
                          onClick={() => setActiveNavGroup(route.name)}
                          to={route.path}>
                          <NavItemText $active={activeNavGroup === route.name}>
                            {route.icon && <route.icon style={{ height: 15 }}/> }
                            <span>{route.name}</span>
                          </NavItemText>
                        </NavItem>
                      })}
                  </div>
                </div>

              </RadomNav>
            </RadomNavContainer>

            <div style={{
              marginLeft: NavbarController.isFullscreen ? 0 : 231,
              transition: '0.2s ease all',
              width: '100%'
            }}>
              <div style={{ width: '100%' }}>

                <div style={{
                  marginTop: NavbarController.isFullscreen ? '-55px' : 0,
                  position: 'sticky',
                  top: NavbarController.isFullscreen ? 'initial' : 0,
                  display: 'flex',
                  background: 'rgba(255, 255, 255, 1)',
                  // borderBottom: `1px solid ${RADOM_COLORS.GRAY_MED}`,
                  // backdropFilter: 'blur(5px)',
                  padding: '10px 20px',
                  width: '100%',
                  zIndex: 3
                }}>

                  {
                    <div style={{ borderRadius: 5, display: 'flex', flexGrow: 1 }}>
                      <SearchBox />
                    </div>
                  }

                  <div style={{ display: 'flex', height: '100%', alignItems: 'center', gap: 5 }}>
                    <Tooltip message={
                      User.organization?.verificationStatus !== 'Verified' ? 'To enable production payments, please verify your organization.' : 'Filter between test and production payments'}>
                      <SecondaryButton
                        disabled={User.organization?.verificationStatus !== 'Verified'}
                        onClick={() => setTestMode(!testMode)}
                        style={{
                          border: 'none',
                          boxShadow: 'none',
                          gap: 10,
                          display: 'flex',
                          alignItems: 'center',
                          color: RADOM_COLORS.BLACK,
                          marginLeft: 20,
                          // backgroundColor: RADOM_COLORS.GRAY9,
                          opacity: 1,
                          // borderColor: RADOM_COLORS.GRAY9,
                          padding: '5px 10px'
                        }}>
                        <span style={{ textTransform: 'uppercase', fontSize: 10, color: RADOM_COLORS.GRAY_DARKEST }}>
                          Test mode
                        </span>
                        <Switch checked={testMode} sizeScale={0.7} />
                      </SecondaryButton>
                    </Tooltip>

                    <ExternalLink
                      $$active={false}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        padding: '0 10px',
                        columnGap: 5,
                        textDecoration: 'none'
                      }}
                      href='https://docs.radom.com'
                      target="_blank" rel="noreferrer">
                      Docs
                      <LinkExternal stroke={RADOM_COLORS.GRAY_DARKER} width={15} style={{ marginBottom: -1 }} />
                    </ExternalLink>

                    <div onClick={onClickNotifications}>
                      <Dropdown
                        transparent
                        noChevron
                        innerStyle={{ maxHeight: '75vh' }}
                        onClose={() => {
                          if (Object.entries(EventsState.latestBlockScanned)
                            .some(([chainID, latestBlockScanned]) => latestBlockScanned > latestBlockAcks[chainID])) {
                            setLatestBlockAcks(EventsState.latestBlockScanned)
                          }
                          setLatestManagedAck(new Date())
                        }}
                        onCloseFn={fn => setNotificationsCloseFn(() => fn)}
                        selectedContent={
                          <div style={{ position: 'relative' }}>
                            {
                              numNotifications > 0 && <div style={{
                                padding: 4,
                                borderRadius: '100%',
                                backgroundColor: RADOM_COLORS.ORANGE,
                                border: '2px solid white',
                                color: '#FFF',
                                fontWeight: 600,
                                fontSize: 10,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                position: 'absolute',
                                top: -2,
                                right: -4
                              }} />
                            }
                            <NotificationBell style={{ height: 18, width: 18 }} fill={RADOM_COLORS.GRAY_DARKER} stroke={RADOM_COLORS.GRAY_DARKER} strokeWidth="2" />
                          </div>
                        }
                        dropdownContent={
                          <div style={{ minWidth: 300, maxHeight: '75vh', overflow: 'hidden', fontSize: 14, display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                            <div style={{
                              width: '100%',
                              padding: '25px 20px',
                              fontSize: 16,
                              fontWeight: 500,
                              display: 'flex',
                              alignItems: 'center',
                              gap: 5
                            }}>
                          Notifications
                            </div>
                            {
                              EventsState.allEvents.length <= 0 &&
                          <div style={{
                            color: RADOM_COLORS.GRAY_DARKER,
                            padding: '80px 20px'
                          }}>No notifications yet</div>
                            }
                            <div style={{ width: '100%', overflow: 'scroll' }}>
                              {
                                EventsState.allEvents.slice(0, 100).map((e, i) => [
                                  <Notification key={i} onClick={() => {
                                    navigate(e.url)
                                    setLatestBlockAcks(EventsState.latestBlockScanned)
                                    notificationsCloseFn()
                                  }}>
                                    {
                                      e.gateway === 'Managed' &&
                                  e.date.getTime() > latestManagedAck.getTime() &&
                                  <div style={{
                                    width: 10,
                                    height: 10,
                                    borderRadius: '100%',
                                    backgroundColor: RADOM_COLORS.ORANGE
                                  }} />
                                    }
                                    <div style={{ display: 'flex', justifyContent: 'space-between', flexGrow: 1, gap: 80 }}>
                                      <div style={{ display: 'flex', alignItems: 'center', gap: 15 }}>
                                        {
                                          e.label === 'Withdrawal' && <Withdrawal style={{ height: 30, width: 30 }} />
                                        }
                                        {
                                          (e.label === 'Order' ||
                                       e.label === 'Payment' ||
                                        e.name === 'RecurringPaymentEvent' ||
                                        e.name === 'SubscriptionPaymentEvent'
                                          ) &&
                                        <OrderIcon style={{ height: 30, width: 30 }} />
                                        }
                                        {

                                          e.name === 'IncompletePaymentEvent' &&
                                        <SubscriptionRevoked style={{ height: 30, width: 30 }} />
                                        }
                                        {
                                          e.name === 'NewSubscription' &&
                                        <NewSubscription style={{ width: 30, height: 30 }} />
                                        }
                                        {
                                          e.name === 'SubscriptionCancelled' && <SubscriptionRevoked style={{ height: 30, width: 30 }} />
                                        }
                                        {
                                          e.label === 'Metered charge' && <MeteredCharge style={{ height: 30, width: 30 }} />
                                        }
                                        {
                                          e.label === 'Role created' && <RoleCreated style={{ height: 30, width: 30 }} />
                                        }
                                        {
                                          e.label === 'Role unassigned' && <RoleUnassigned style={{ height: 30, width: 30 }} />
                                        }
                                        {
                                          e.label === 'Role updated' && <RoleUpdated style={{ height: 30, width: 30 }} />
                                        }
                                        {
                                          e.label === 'Role deleted' && <RoleDeleted style={{ height: 30, width: 30 }} />
                                        }
                                        {
                                          e.label === 'Role assigned' && <RoleAssigned style={{ height: 30, width: 30 }} />
                                        }
                                        {
                                          e.label === 'Refund' && <Refund style={{ height: 30, width: 30 }} />
                                        }
                                        <div style={{ display: 'flex', flexDirection: 'column', flexGrow: 1, gap: 4 }}>
                                          <span>{e.label}</span>
                                          <div style={{ display: 'flex', alignItems: 'center', gap: 3 }}>
                                            {
                                              e.gateway === 'Managed' && (e.name === 'PaymentEvent' || e.name === 'RecurringPaymentEvent' || e.name === 'SubscriptionPaymentEvent') && <>
                                                <img src={e.paymentMethod.logo} style={{
                                                  height: 16,
                                                  width: 'auto',
                                                  maxWidth: 16
                                                }} />
                                                <span style={{ opacity: 0.5, whiteSpace: 'pre' }}>
                                                  {e.paymentMethod.hostChain
                                                    ? e.paymentMethod.hostChain.name
                                                    : e.paymentMethod.name}
                                                </span>
                                              </>
                                            }
                                            {
                                              e.gateway === 'Managed' && (e.name === 'Withdrawal' || e.name === 'NewSubscription' || e.name === 'Refund') && <>
                                                <img src={e.managedMethod.logo} style={{
                                                  height: 16,
                                                  width: 'auto',
                                                  maxWidth: 16
                                                }} />
                                                <span style={{ opacity: 0.5, whiteSpace: 'pre' }}>
                                                  {e.managedMethod.hostChain
                                                    ? e.managedMethod.hostChain.name
                                                    : e.managedMethod.name}
                                                </span>
                                              </>
                                            }
                                          </div>
                                        </div>
                                      </div>
                                      <span style={{ color: RADOM_COLORS.GRAY_DARKER }}>
                                        {formatRelative(e.date, new Date())}
                                      </span>
                                    </div>
                                  </Notification>
                                ])
                              }
                            </div>
                          </div>
                        }
                      />
                    </div>

                    <Dropdown
                      transparent
                      innerStyle={{ maxHeight: '75vh' }}
                      selectedContent={
                        <div style={{ position: 'relative' }}>
                          {isLoadingProfile
                            ? <LoadingBar style={{ width: 20, height: 20 }} />
                            : <img src={ profile?.logoUrl || DefaultSeller} style={{
                              height: 20,
                              width: 'auto',
                              filter: 'invert(30%) contrast(180%) saturate(130%)'
                            }} />
                          }
                        </div>
                      }
                      dropdownContent={
                        <div style={{
                          minWidth: 380,
                          maxHeight: '75vh',
                          overflow: 'hidden',
                          fontSize: 14,
                          display: 'flex',
                          alignItems: 'center',
                          flexDirection: 'column'
                        }}>

                          {
                            User._user && <>

                              <div style={{
                                width: '100%',
                                padding: 20,
                                borderBottom: `1px solid ${RADOM_COLORS.GRAY_MED}`,
                                display: 'flex',
                                gap: 10,
                                alignItems: 'center'
                              }}>
                                <div style={{
                                  display: 'flex',
                                  flexDirection: 'column'
                                }}>
                                  {
                                    User._user.firstName &&
                                  <span style={{ color: RADOM_COLORS.GRAY_DARKER }}>{User._user.firstName}</span>
                                  }
                                  <span style={{ color: RADOM_COLORS.GRAY_DARKER }}>
                                Acccount
                                  </span>
                                  <span style={{ color: RADOM_COLORS.GRAY_DARKEST }}>
                                    {User._user.evmAddress && !User._user.emailAddress
                                      ? User._user.evmAddress
                                      : User._user.emailAddress}
                                  </span>
                                </div>

                              </div>

                              <SecondaryButton
                                onClick={() => {
                                  loadOrganizations()
                                  setOrganizationModalVisible(true)
                                }}
                                style={{
                                  width: '100%',
                                  padding: 20,
                                  outline: 0,
                                  background: 'none',
                                  border: 0,
                                  borderBottom: `1px solid ${RADOM_COLORS.GRAY_MED}`,
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                  alignItems: 'center',
                                  borderRadius: 0,
                                  gap: 30
                                }}>
                                <div style={{
                                  display: 'flex',
                                  gap: 10,
                                  alignItems: 'center',
                                  justifyContent: 'center'
                                }}>
                                  {
                                    organizations.find(o =>
                                      o.organizationId === User.selectedOrganizationId)?.logoUrl &&
                                    <img
                                      src={organizations.find(o =>
                                        o.organizationId === User.selectedOrganizationId)?.logoUrl}
                                      style={{
                                        width: 'auto',
                                        maxHeight: 30,
                                        maxWidth: 100,
                                        filter: 'invert(30%) contrast(180%) saturate(130%)'
                                      }}
                                    />
                                  }
                                  <div style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'flex-start'
                                  }}>
                                    <span style={{ color: RADOM_COLORS.GRAY_DARKER }}>Organization</span>
                                    <span style={{ color: RADOM_COLORS.BLACK }}>
                                      {
                                        organizations.find(o =>
                                          o.organizationId === User.selectedOrganizationId)?.name ||
                                        User.selectedOrganizationId
                                      }
                                    </span>
                                  </div>
                                </div>
                                <Chevron
                                  fill={RADOM_COLORS.GRAY_DARKER}
                                  style={{
                                    opacity: 0.5,
                                    marginBottom: -1,
                                    transition: '0.2s ease all',
                                    transform: 'rotate(-90deg)',
                                    minWidth: 10,
                                    height: 'auto'
                                  }} />
                              </SecondaryButton>

                              <ConfirmButtonModal
                                title='Create new organization'
                                description='Please confirm that you want to create a new organization.'
                                action='Create organization'
                                onConfirm={async () => {
                                  Radom.createOrganization()
                                    .then(async ({ organizationId }) =>
                                      await Radom.updateUser({ defaultOrganizationId: organizationId }))
                                    .then(() => {
                                      window.location = '/' as any
                                    }).catch(err => errorToast(err.response?.data?.error || err.message))
                                }}
                                ActionComponent={props =>
                                  <SecondaryButton style={{
                                    border: 0,
                                    width: '100%',
                                    gap: 10,
                                    display: 'flex',
                                    alignItems: 'center',
                                    padding: '15px 20px',
                                    borderBottom: `1px solid ${RADOM_COLORS.GRAY_MED}`,
                                    borderRadius: 0,
                                    color: RADOM_COLORS.BLACK
                                  }} {...props}>
                                    <span style={{ fontSize: 20, marginTop: -3 }}>+</span>
                                    <span>New organization</span>
                                  </SecondaryButton>
                                }
                              />
                            </>
                          }

                          {
                            organizations.find(o => o.organizationId === '00000000-0000-0000-0000-000000000000' || o.organizationId === '00000000-0000-0000-0000-000000000999') &&
                        <div style={{
                          display: 'flex',
                          flexDirection: 'column',
                          backgroundColor: RADOM_COLORS.GRAY_DARKEST,
                          width: '100%',
                          color: 'white'
                        }}>
                          <span style={{
                            padding: '10px 20px',
                            backgroundColor: RADOM_COLORS.BLACK
                          }}>Admin tools</span>
                          <SecondaryButton
                            onClick={() => {
                              if (localStorage.getItem('IMPERSONATION_ORG_ID')) {
                                localStorage.removeItem('IMPERSONATION_ORG_ID')
                                window.location = '/' as any
                                return
                              }
                              setImpersonationModalOpen(true)
                            }}
                            style={{
                              border: 0,
                              width: '100%',
                              gap: 10,
                              display: 'flex',
                              alignItems: 'center',
                              padding: '15px 20px',
                              borderBottom: `1px solid ${RADOM_COLORS.GRAY_MED}`,
                              color: 'white',
                              borderRadius: 0
                            }}>
                            {
                              localStorage.getItem('IMPERSONATION_ORG_ID')
                                ? 'Stop impersonating'
                                : 'Impersonate organization'
                            }
                          </SecondaryButton>
                        </div>
                          }

                          <ConfirmButtonModal
                            title='Sign out'
                            description='Are you sure you want to sign out?'
                            action='Sign out'
                            onConfirm={async () => {
                              Radom.signOut()
                            }}
                            ActionComponent={props =>
                              <SecondaryButton style={{
                                width: '100%',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                position: 'relative',
                                borderRadius: 0,
                                gap: 5,
                                border: 0,
                                color: RADOM_COLORS.GRAY_DARKER
                              }} {...props}>
                                <span>Sign out</span>
                                <SignOut style={{ height: 18, color: RADOM_COLORS.GRAY_DARKER }} />
                              </SecondaryButton>
                            }
                          />

                        </div>
                      }
                    />
                  </div>
                </div>

                {
                  testMode && !NavbarController.isFullscreen &&
                  <TestModeWrapper>
                    <TestModeLabel>Test mode</TestModeLabel>
                  </TestModeWrapper>
                }

                <Routes>
                  {
                    routes.map((route, index) => <Route
                      key={index}
                      path={route.path}
                      element={route.element}
                    />
                    )
                  }
                  <Route path="*" element={<NotFound />} />
                </Routes>
                <InviteAcceptModal />
              </div>
            </div>

          </div>

          <Modal
            visible={organizationModalVisible}
            onClose={() => setOrganizationModalVisible(false)}
            title='Select organization'>

            <div style={{
              display: 'flex',
              flexDirection: 'column',
              flexGrow: 1,
              fontSize: 14
            }}>
              {
                organizations.map(o =>
                  <SecondaryButton
                    onClick={() => {
                      Radom.updateUser({ defaultOrganizationId: o.organizationId }).then(() => {
                        window.location = '/' as any
                      })
                    }}
                    key={o.organizationId}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      padding: 20,
                      border: 0,
                      borderBottom: `1px solid ${RADOM_COLORS.GRAY_DARK}`,
                      gap: 30,
                      borderRadius: 0
                    }}>
                    <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
                      {
                        <div style={{
                          padding: 5,
                          borderRadius: 5,
                          fontSize: 0
                        }}>
                          <img src={o.logoUrl || DefaultSeller} style={{
                            width: 'auto',
                            maxHeight: 30,
                            maxWidth: 100,
                            filter: 'invert(30%) contrast(180%) saturate(130%)'
                          }} />
                        </div>
                      }
                      <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start'
                      }}>
                        <span style={{ color: RADOM_COLORS.GRAY_DARKEST }}>{o.name || o.organizationId}</span>
                        {
                          o.name &&
                              <span style={{ color: RADOM_COLORS.GRAY_DARKEST, opacity: 0.5 }}>{o.organizationId}</span>
                        }
                      </div>
                    </div>
                    {
                      User.selectedOrganizationId === o.organizationId &&
                          <span style={{
                            fontSize: 10,
                            textTransform: 'uppercase',
                            color: RADOM_COLORS.GRAY_DARKEST,
                            backgroundColor: RADOM_COLORS.GRAY_MED,
                            borderRadius: 5,
                            padding: '5px 10px'
                          }}>Selected</span>
                    }
                  </SecondaryButton>
                )
              }
            </div>

          </Modal>

          <Modal
            title='Impersonate organization'
            visible={impersonationModalOpen}
            onClose={() => setImpersonationModalOpen(false)}>
            <div style={{ display: 'flex', padding: '0 30px 30px 30px', width: '100%' }}>

              <div style={{ display: 'flex', flexDirection: 'column', gap: 5, width: '100%' }}>
                <InputLabel style={{ color: RADOM_COLORS.NEW_GRAY_1 }}>
                      Organzation ID
                </InputLabel>
                <TextInput
                  placeholder='00000000-0000-0000-0000-000000000000'
                  value={impersonationOrgId}
                  onChange={e => {
                    setImpersonationOrgId(e.target.value)
                  }} />
              </div>
            </div>

            <div style={{ padding: '0 30px 30px 30px' }}>
              <PrimaryButton
                style={{ width: '100%' }}
                onClick={() => {
                  localStorage.setItem('IMPERSONATION_ORG_ID', impersonationOrgId)
                  window.location.reload()
                }}>
                  Impersonate
              </PrimaryButton>
            </div>
          </Modal>
        </WithAuthentication>
        <Toaster position="bottom-right" />
      </GoogleOAuthProvider>
    </div>
  )
})

export default App
