import axios from 'axios'
import { BigNumber } from 'ethers'
import { makeAutoObservable } from 'mobx'
import { API_BASE_URL, RADOM_TOKEN_EXPIRY_LOCAL_STORAGE_KEY, RADOM_TOKEN_LOCAL_STORAGE_KEY } from '../util/Constants'
import { errorToast, identifyVisitor, successToast } from '../util/Util'
import UserState from './User'

interface IAuthRequestResponse {
  nonce: string
}

interface IAuthTokenResponse {
  jwtToken: string
  requires2fa: boolean
  expiresAt: string
}

export interface LatestAck {
  chainId?: number
  lastBlockAck?: number
  managedLastAck?: string
}

export type VerificationStatus = 'Unverified' | 'PendingVerificationSubmission' | 'PendingApproval' | 'Rejected' | 'Verified'

export interface BusinessBasics {
  name: string
  description: string
  businessType: BusinessTypes
  website: string
  email: string
}

export interface BusinessAddress {
  streetLine: string
  city: string
  state: string
  postalCode: string
  country: string
}

export interface LegalAndTaxDetails {
  taxIdentificationNumber: string
  isDao: boolean
}

export interface FinancialInformation {
  sourceOfFunds: string
  statementOfFunds: string
}

export interface RegulatoryCompliance {
  transmitsCustomerFunds: boolean
  complianceScreeningExplanation: string
}

export interface Ubo {
  firstName: string
  lastName: string
  birthDate?: string
  email: string
  phone: string
  taxIdentificationNumber: string
  address: {
    streetLine: string
    city: string
    state: string
    postalCode: string
    country: string
  }
  percentageOwnership: number
  hasControl: boolean
  isSigner: boolean
  relationshipEstablishedAt?: string
  govIdImage: string
}

export interface Documents {
  formationDocument?: string
  ownershipDocument?: string
}

export interface IVerification extends
  BusinessBasics,
  BusinessAddress,
  LegalAndTaxDetails,
  FinancialInformation,
  RegulatoryCompliance,
  Documents {
  id: string
  status: VerificationStatus
  ubos: Ubo[]
}

export enum BusinessTypes {
  cooperative = 'Cooperative',
  corporation = 'Corporation',
  llc = 'LLC',
  other = 'Other',
  partnership = 'Partnership',
  sole_prop = 'Sole Proprietorship',
  trust = 'Trust'
}

export type UpdateVerificationPayload =
  BusinessBasics
  | BusinessAddress
  | LegalAndTaxDetails
  | FinancialInformation
  | RegulatoryCompliance
  | Ubo[]
  | Documents
  | null

export interface IOrganization {
  organizationId: string
  name?: string
  logoUrl?: string
  website?: string
  telegram?: string
  discord?: string
  twitter?: string
  imageBytes?: any
  defaultTokens?: AcceptedToken[]
  features?: {
    isPresaleEnabled: boolean
  }
  defaultCurrency: string
  verificationId?: string
  verificationStatus: VerificationStatus
  bridgeCustomer?: BridgeCustomer
}

export interface IDefaultToken {
  chainId: number
  tokenAddress: string
}

export type ICreateSellerProfile = Omit<IOrganization, 'logoUrl'> & {
  defaultTokens?: IDefaultToken[]
  imageBytes?: number[]
}

export interface IProductCreate {
  name: string
  description?: string
  addOns: Array<{ name: string, price: BigNumber }>
  chargingIntervalSeconds?: number
  currency: string
  price: number
  quantity?: number
  sendSubscriptionEmails?: boolean
  productType?: {
    Presale?: {
      Token?: {
        decimals?: any
        ticker?: any
      }
    }
  }
}

export type IProductUpdate = Partial<IProductCreate> & { id: string }
export type IProduct = Omit<IProductCreate, 'image'> & {
  createdAt: string
  updatedAt: string
  id: string
  imageUrl: string
  isArchived: boolean
}

export interface ProductWithQuantity {
  product: IProduct
  quantity: number
}

export interface LineItem {
  invoiceId: string
  name: string
  quantity: number
  price: number
  currency?: string
}

export interface AcceptedToken {
  tokenAddress: string
  chainId: number
}

export enum InputFieldDataType {
  String = 'String',
  Number = 'Number',
  Email = 'Email',
  Address = 'Address',
  EthereumAddress = 'EVM Address',
  SolanaAddress = 'Solana Address'
}

export interface InputField {
  dataType: InputFieldDataType
  inputLabel: string
  isRequired: boolean
}

export type ICreateInputField = Omit<InputField, 'dataType'> & { dataType: string }

export interface IPaymentMethod {
  network: string
  token?: string
}

export interface IManagedGateway {
  methods: IPaymentMethod[]
}

export interface ISelfCustodialGateway {
  tokens: AcceptedToken[]
}

export interface CreateUpdatePaymentLinkReq {
  products: string[]
  gateway: {
    selfCustodial?: ISelfCustodialGateway
    managed?: IManagedGateway
  }
  customizations: Customization
  cancelUrl: string
  successUrl: string
  inputFields: InputField[]
  sendEmailReceipt?: boolean
  chargeCustomerNetworkFee?: boolean
  presaleData?: PresaleRequestData
}

export interface PresaleRequestData {
  totalRaiseAmount?: string
  minimumPurchaseAmount?: string
  startDate?: string
  endDate?: string
  pricingStrategies?: {
    timeBased?: any
    goalBased?: any
  }
}

export interface IPaymentLink {
  cancelUrl: string
  successUrl: string
  id: string
  products: IProduct[]
  gateway: {
    selfCustodial?: ISelfCustodialGateway
    managed?: IManagedGateway
  }
  url: string
  createdAt: Date
  updatedAt: Date
  customizations: Customization
  inputFields: InputField[]
  sendEmailReceipt?: boolean
  chargeCustomerNetworkFee?: boolean
  isPresale?: boolean
  presaleData?: PresaleData
}

export interface PresaleData {
  tokenPresaleData?: {
    totalRaiseAmount?: string
    minimumPurchaseAmount?: string
    startsAt?: string
    endsAt?: string
    pricingStrategies?: {
      timeBased?: {
        startsAt: string
        roundDurationSeconds: number
        priceIncreasePercentage: number
      }
      goalBased?: any
    }
    customizations?: {
      title?: string
      titleFontSize?: string
      hideTotalRaiseGoal?: boolean
    }
  }
}

export interface IDonationLink {
  bannerImageUrl?: string
  imageUrl?: string
  id: string
  url: string
  name: string
  currency: string
  createdAt: Date
  updatedAt: Date
  primaryButtonColor: string
  inputFields: InputField[]
  methods: IPaymentMethod[]
  tosUrl?: string
}

export interface ICreateCheckoutSession {
  gateway: {
    selfCustodial?: ISelfCustodialGateway
    managed?: IManagedGateway
  }
  currency?: string
  total?: number
  cancelUrl?: string
  successUrl?: string
}

export interface ICheckoutOrderDetails {
  id: string
  checkoutSessionId: string
  organizationId: string
  buyerAddress: string
  tokenAddress: string
  chainId: number
  createdAt: Date
  paidAt: Date
  orderData: ICheckoutOrderData[]
  orderProductData: ICheckoutProductOrderData[]
}

interface ICheckoutOrderData {
  id: string
  orderId: string
  key: string
  value: string
}

interface ICheckoutProductOrderData {
  id: string
  orderId: string
  productId: string
  key: string
  value: string
}

export interface Customization {
  leftPanelColor: string
  leftPanelGradient?: string
  primaryButtonColor: string
  slantedEdge: boolean
  allowDiscountCodes: boolean
}

export interface ICreatePaymentLink {
  products: string[]
  customizations: Customization
  inputFields?: ICreateInputField[]
  sendEmailReceipt?: boolean
}

export interface ICreateDonationLink {
  primaryButtonColor: string
  inputFields?: ICreateInputField[]
  sendEmailReceipt?: boolean
}

export interface IDiscountCode {
  id: string
  code: string
  model: any
  products: string[]
  startsAt?: string
  expiresAt?: string
  createdAt: string
  updatedAt: string
}

export interface Customer {
  id: string
  totalSpend: string
  email: string
  name: string
  billingAddress?: string
  phone?: string
  telegram?: string
  discord?: string
  payments: Array<{
    managed?: ManagedPaymentDetails
  }>
  tags: string[]
  createdAt: string
}

export type ICreateCustomer = Omit<Customer, 'id'>

export interface Invoice {
  id: string
  gateway: {
    selfCustodial?: ISelfCustodialGateway
    managed?: IManagedGateway
  }
  organizationId: string
  seller?: IOrganization
  customer: Customer
  products: ProductWithQuantity[]
  lineItems: LineItem[]
  issuedAt: Date
  paidAt?: Date
  voidedAt?: Date
  overdueAt?: Date
  inputData: Array<{ key: string, value: string }>
  status: 'paid' | 'pending' | 'voided' | 'overdue'
  memo?: string
  url: string
  payment?: {
    managed?: ManagedPaymentDetails
  }
}

export interface ListInvoice {
  id: string
  invoiceUrl: string
  status: 'paid' | 'pending' | 'voided' | 'overdue'
  email: string
  currency: string
  amount: number
  issuedAt: Date
  paidAt?: Date
  voidedAt?: Date
  overdueAt?: Date
}

export type ICreateInvoice = Omit<Invoice, 'id' | 'organizationId' | 'customer' | 'issuedAt' | 'paidAt' | 'voidedAt' | 'status'> & { 'customerIds': string[] }
export type MultiCreateInvoice = Omit<ICreateInvoice, 'customerIds' | 'organizationId'> & { customers: Customer[], customer?: Customer }

export interface IToken {
  sessionId: string
  name: string
  token?: string
  createdAt: string
}

export interface OtpAuthResponse {
  otpAuthUrl: string
  otpBase32: string
}

export interface IWebhook {
  id: string
  url: string
  organizationId: string
  verificationKey: string
  isPaused: boolean
  isDeleted: boolean
  createdTimestamp: string
  pausedTimestamp: string
}

export interface IWebhookMessage {
  id: string
  webhookId: string
  messagePayload: any
  messageStatus: string
  createdAt: Date
  nextDeliveryAttemptTimestamp: Date
  numFailedDeliveryAttempts: number
  deliveryResponseStatusCode?: number
}

export interface IPermissionRole {
  id: string
  name: string
  organizationId: string
  permissions: string[]
  createdAt: string
  updatedAt: string
  users: string[]
}

export interface ILogEvent {
  organizationId: string
  chainId: number
  blockNumber: number
  blockTimestamp: string
  logMessage: string
}

export interface IPriceQuote {
  from: string
  to: string
  value: number
  expiryTimestamp: string
}

export interface WalletShare {
  id: string
  loginId: string
  loginType: string
  share: string
  createdAt: string
}

export interface KeyValuePair {
  key: string
  value: string
}

export interface CheckoutItemData {
  name: string
  description?: string
  chargingIntervalSeconds?: number
  price: string
  imageUrl?: string
  isMetered?: boolean
  currency: string
}

export interface ICheckoutSession {
  id: string
  organizationId: string
  total: number
  currency?: string
  successUrl: string
  cancelUrl?: string
  sessionStatus: 'pending' | 'success' | 'cancelled' | 'expired'
  expiresAt?: string
  createdAt: string
  updatedAt: string
  customizations: Object
  items?: CheckoutItemData[]
  products?: IProduct[]
  gateway: {
    selfCustodial?: ISelfCustodialGateway
    managed?: IManagedGateway
  }
  metadata: Array<{ key: string, value: string }>
  payment?: {
    managed?: ManagedPaymentDetails
    managedTokenDelegatePayment?: ManagedPaymentDetails
  }
  refunds: Refund[]
}

export interface Refund {
  status: string
  network: string
  token?: string
  amount: number
  chargeCustomerNetworkFee: boolean
  recipientAddress: string
  transactionHash?: string
  createdAt: string
}

export interface CheckoutOrder {
  id: string
  checkoutSessionId: string
  organizationId: string
  sellerPaymentAddress: string
  buyerAddress: string
  tokenAddress: string
  chainId: number
  orderHash: string
  createdAt: Date
  paidAt: Date
}

export interface CheckoutOrderSession {
  order: CheckoutOrder
  session: ICheckoutSession
  metadata: KeyValuePair[]
}

export interface PaymentLinkOrderData {
  key: string
  value: string
}

export interface IManagedPaymentMethodDetails {
  paymentId: string
  paymentAddress: string
  network: string
  token?: string
  amount: string
  networkFee: string
  expiresAt: string
}

export interface PaymentMethodDetails {
  managed?: IManagedPaymentMethodDetails
  selfCustodial?: {
    sellerAddress: string
    buyerAddress: string
    tokenAddress: string
    chainId: number
    orderHash: string
    orderInput: string
  }
}

export interface ManagedTransaction {
  network: string
  token: string
  amount: string
  transactionHash: string
  blockTimestamp: string
}

export interface PaymentLinkOrder {
  id: string
  paymentLinkId: string
  paymentMethodDetails: PaymentMethodDetails
  payment: {
    managed?: ManagedPaymentDetails
    managedTokenDelegatePayment?: ManagedPaymentDetails
  }
  orderData: PaymentLinkOrderData[]
  paidAt: string
  createdAt: string
}

export interface DonationLinkOrder {
  id: string
  donationLinkId: string
  paymentMethodDetails: PaymentMethodDetails
  payment: {
    managed?: ManagedPaymentDetails
    managedTokenDelegatePayment?: ManagedPaymentDetails
  }
  orderData: PaymentLinkOrderData[]
  paidAt: string
  createdAt: string
  refunds: Refund[]
}

export interface ConversionRate {
  from: string
  to: string
  rate: string
}

export interface ManagedPaymentDetails {
  id?: string
  paymentEventId: string
  amount: string
  conversionRates: ConversionRate[]
  network: string
  token?: string
  networkFee: string
  transactions: ManagedTransaction[]
}

export interface Metadata {
  key: string
  value: string
}

export interface ManagedBalance {
  network: string
  token?: string
  balance: string
}

export interface WithdrawalRequestReceipt {
  EVMReceipt?: {
    transactionHash: string
    networkFeeAmount?: string
  }
  BTCReceipt?: {
    transactionId: string
    networkFeeAmount?: string
  }
  SOLReceipt?: {
    signature: string
    networkFeeAmount?: string
  }
  DOTReceipt?: {
    transactionHash: string
    networkFeeAmount?: string
  }
}

export interface WithdrawalRequest {
  id: string
  organizationId: string
  network: string
  token?: string
  withdrawalAmount: string
  withdrawalAddress: string
  requestedAt: string
  withdrawalStatus: string
  completedAt?: string
  receipt?: WithdrawalRequestReceipt
}

export interface ListWithdrawalsResponse {
  data: WithdrawalRequest[]
  total: number
}

export interface PayoutEntry {
  id: string
  payoutType: {
    crypto?: {
      network: string
      token?: string
      amount: string
      payoutAddress: string
    }
  }
  status: 'AwaitingConfirmation' | 'Pending' | 'Processing' | 'Complete' | 'Expired'
  payoutEntryReceipt?: {
    crypto?: {
      txHash: string
    }
  }
}

export interface Payout {
  id: string
  status: 'AwaitingConfirmation' | 'Pending' | 'Processing' | 'Complete' | 'Expired'
  payouts: PayoutEntry[]
  totalPayoutUsd: string
  estimatedFeeUsd: string
  receipt?: any
  feeBreakdown: any
  expiresAt: string
  completedAt: string
  createdAt: string
}

export interface ListPayoutsResponse {
  data: Payout[]
  total: number
}

export interface CreatePayoutEntry {
  crypto?: {
    network: string
    token?: string
    amount: string
    payout_address: string
  }
}

export interface ManagedEventPaymentData {
  amount: string
  paymentMethod: {
    network: string
    token: string
  }
  radomData: {
    checkoutSession?: {
      checkoutSessionId: string
    }
    paymentLink?: {
      paymentLinkId: string
      paymentLinkOrderId: string
    }
    invoice?: {
      invoiceId: string
    }
    paymentSession?: {
      paymentSessionId: string
    }
    subscription?: {
      subscriptionId: string
    }
    donationLink?: {
      donationLinkId: string
      donationLinkOrderId: string
    }
  }
}

export interface ManagedEventNewSubscription {
  id: string
  subscriptionId: string
  subscriptionType: {
    automatedEVMSubscription?: {
      buyerAddress: string
      subscriptionContractAddress: string
      recommendedAllowanceDuration?: number
    }
  }
  network: string
  token?: string
  amount: string
  currency: string
  period: ManagedSubscriptionPeriod
  periodCustomDuration?: number
  tags?: { [key: string]: string }
  createdAt: string
}

export type ManagedEventSubscriptionCancelled = ManagedEventNewSubscription & { cancelledAt: string }
export type ManagedEventSubscriptionExpired = ManagedEventNewSubscription & { expiredAt: string }

export interface ManagedEventWithdrawalData {
  withdrawalRequestId: string
  network: string
  token?: string
  withdrawalAmount: string
  isSuccess: boolean
  failureReason?: string
  receipt?: WithdrawalRequestReceipt
}

export interface ManagedEventData {
  payment: ManagedEventPaymentData
  withdrawal: ManagedEventWithdrawalData
  refund: ManagedEventWithdrawalData
  recurringPayment: ManagedEventPaymentData
  incompletePayment: ManagedEventPaymentData
  newSubscription: ManagedEventNewSubscription
  subscriptionCancelled: ManagedEventSubscriptionCancelled
  subscriptionExpired: ManagedEventSubscriptionExpired
  subscriptionPayment: ManagedEventPaymentData
}

export type ManagedEventType = 'payment' |
                        'withdrawal' |
                        'recurringPayment' |
                        'newSubscription' |
                        'incompletePayment' |
                        'refund' |
                        'subscriptionPayment' |
                        'subscriptionCancelled' |
                        'subscriptionExpired'

export interface ManagedEvent {
  id: string
  organizationId: string
  eventType: ManagedEventType
  eventData: ManagedEventData
  eventDate: string
}

export interface ManagedPaymentStatus {
  isComplete: boolean
  network?: string
  token?: string
  amount?: BigNumber
}

export type PaymentSessionStatus = 'pending' | 'success' | 'cancelled' | 'expired'

export interface PaymentSession {
  id: string
  method: IPaymentMethod
  total: number
  currency: string
  sessionStatus: PaymentSessionStatus
  paymentTotal: number
  paymentAddress?: string
  transactions: ManagedTransaction[]
  expiresAt?: string
  createdAt: string
  payment?: {
    managed?: ManagedPaymentDetails
    managedTokenDelegatePayment?: ManagedPaymentDetails
  }
  refunds: Refund[]
}

export interface Payment {
  id: string
  organizationId: string
  associatedEntityType: AssociatedEntityType
  associatedEntityId: string
  currency?: string
  subtotal?: number
  total?: number
  network: string
  token?: string
  amount: number
  networkFee: number
  conversionRates: ConversionRate[]
  discountCodeId?: string
  discountAmount?: number
  feeAamount?: number
  netAmount?: number
  customerId?: string
  startedAt: string
  completedAt: string
}

export type ManagedSubscriptionPeriod = 'daily' | 'weekly' | 'monthly' | 'quarterly' | 'annually' | 'custom'

export type SubscriptionStatus = 'active' | 'cancelled' | 'expired'

type AssociatedEntityType = 'paymentLinkOrder' | 'checkoutSession' | 'invoice' | 'paymentSession' | 'DonationLinkOrder'
export interface ISubscription {
  product?: any
  id: string
  organizationId: string
  associatedEntityType: AssociatedEntityType
  associatedEntityId: string
  subscriptionType: {
    automatedEVMSubscription?: {
      buyerAddress: string
      emailAddress?: string
      subscriptionContractAddress: string
      recommendedAllowanceDuration?: number
    }
    emailInvoiceSubscription?: {
      emailAddress: string
    }
  }
  status: SubscriptionStatus
  network: string
  token?: string
  amount: string
  currency: string
  period: ManagedSubscriptionPeriod
  periodCustomDuration?: number
  tags?: { [key: string]: string }
  nextBillingDateAt: string
  createdAt: string
  expiredAt?: string
  cancelledAt?: string
}

export interface User {
  id: string
  emailAddress?: string
  evmAddress?: string
  defaultOrganizationId: string
  firstName?: string
  lastName?: string
  imageUrl?: string
  otpEnabled: boolean
  otpVerified: boolean
}

export interface TeamInvite {
  id: string
  roles: string[]
  emailAddress?: string
  evmAddress?: string
  status: any
  createdAt: string
  inviteUrl: string
}

export interface Member {
  userId: string
  emailAddress?: string
  evmAddress?: string
  firstName?: string
  lastName?: string
  imageUrl?: string
  lastLoginAt?: string
}

export interface Invite {
  id: string
  organizationId: string
}

export interface SearchResult {
  managedEvent?: ManagedEvent
}

export interface RefundRequest {
  paymentSessionId?: string
  checkoutSessionId?: string
  donationLinkOrderId?: string
  refundAmount: string
  chargeCustomerNetworkFee?: boolean
  recipientAddress: string
}

export interface ConvertRequest {
  from: IPaymentMethod
  into: IPaymentMethod
  amount: string
}

export interface FiatAccountDetails {
  iban?: {
    iban: string
    bic: string
    address: string
  }
  us?: {
    accountNumber: string
    routingNumber: string
    bankName: string
    address: string
  }
}

export interface WithdrawalAccountDetails {
  Crypto?: {
    network: string
    token?: string
    address: string
  }
  Fiat?: {
    accountOwnerType: string
    accountOwnerName: string
    accountType: FiatAccountDetails
  }
}

export interface WithdrawalAccount {
  id: string
  organizationId: string
  accountType: 'crypto' | 'fiat'
  name: string
  status: 'pending' | 'verfied'
  accountDetails: WithdrawalAccountDetails
  createdAt: string
}

export interface Trigger {
  minimumAmount?: {
    amount: string
    currency: string
  }
}

export interface Automation {
  id: string
  organizationId: string
  name: string
  automationType: 'withdrawal'
  automationDetails: {
    Withdrawal?: {
      assets: Array<{ network: string, token?: string }>
      triggres: Trigger[]
      withdrawalAccountId: string
    }
  }
  lastTriggeredAt: string
  createdAt: string
}

export interface VisitorTokenRequest {
  email: string
  first_name: string
  last_name: string
}

export interface VisitorTokenResponse {
  token: string
}

export interface VerifyOrganizationRequest {
  organizationType: 'Individual' | 'Business' | 'Nonprofit'
  name?: string
  website?: string
  country?: string
  industry?: string
}

export interface VerifyOrganizationResponse {
  personaVerificationLink: string
}

export type BridgeCustomerStatus = 'Created' | 'TosApproved' | 'Pending' | 'Accepted' | 'Rejected'

export interface BridgeCustomer {
  id: string
  tosLink: string
  status: BridgeCustomerStatus
  organizationId: string
}

export const DEFAULT_ORGANIZATION_ID = '00000000-0000-0000-0000-000000000000'

// Renew token if it expires within 12 hours or less
const TOKEN_RENEWAL_THRESHOLD_MS = 60 * 60 * 12 * 1000

class Radom {
  isLoggedIn = false
  requires2fa = false
  isLoggingIn = false
  sellerProfile: IOrganization | null = null
  token = ''

  constructor() {
    axios.interceptors.response.use(res => res, async res => {
      return await Promise.reject(res)
    })
    makeAutoObservable(this)
    this.token = localStorage.getItem(RADOM_TOKEN_LOCAL_STORAGE_KEY) ?? ''
    const expiresAt = localStorage.getItem(RADOM_TOKEN_EXPIRY_LOCAL_STORAGE_KEY)
    if (expiresAt) {
      const expiresAtDate = new Date(expiresAt)
      if (expiresAtDate.getTime() - new Date().getTime() <= TOKEN_RENEWAL_THRESHOLD_MS) {
        this.renewToken()
      }
    }
    if (this.token) {
      this.isLoggedIn = true
    }
  }

  renewToken = async (): Promise<void> => {
    const authRenewResponse: IAuthTokenResponse = await this
      .post('/auth/renew')

    this.token = authRenewResponse.jwtToken
    localStorage.setItem(RADOM_TOKEN_LOCAL_STORAGE_KEY, authRenewResponse.jwtToken)
    localStorage.setItem(RADOM_TOKEN_EXPIRY_LOCAL_STORAGE_KEY, new Date(authRenewResponse.expiresAt).toString())
  }

  login = async (): Promise<void> => {
    this.isLoggingIn = true

    try {
      const address = await UserState.connectedAccount

      const authRequest: IAuthRequestResponse = await this.post('/auth/request', { address })

      const signedMessage = await UserState.signer?.signMessage(authRequest.nonce)

      const verifyResponse: IAuthTokenResponse = await this.post('/auth/verify', {
        address,
        signature: signedMessage
      })

      this.token = verifyResponse.jwtToken
      try {
        localStorage.setItem(RADOM_TOKEN_LOCAL_STORAGE_KEY, this.token)
        localStorage.setItem(RADOM_TOKEN_EXPIRY_LOCAL_STORAGE_KEY, new Date(verifyResponse.expiresAt).toString())
      } catch (err) {
        localStorage.clear()
        window.location = '/' as any
      }

      this.isLoggedIn = !verifyResponse.requires2fa
      this.requires2fa = verifyResponse.requires2fa
      if (!verifyResponse.requires2fa) {
        successToast('Successfully signed in!')
      }

      // Ensure token is set before proceeding
      if (this.token) {
        try {
          const visitorTokenResponse = await this.generateVisitorToken()
          localStorage.setItem('visitorToken', visitorTokenResponse.token)
          await identifyVisitor()
        } catch {}
      }
    } catch (err) {
      console.error('Failed to login', err)
      errorToast(err.reason || err.message)
      this.isLoggingIn = false
      throw err
    }

    this.isLoggingIn = false
  }

  loginWithSSO = async (loginType: string, token: string): Promise<void> => {
    this.isLoggingIn = true

    try {
      const verifyResponse: IAuthTokenResponse = await this.post('/auth/sso', {
        loginType,
        token
      })

      this.token = verifyResponse.jwtToken
      try {
        localStorage.setItem(RADOM_TOKEN_LOCAL_STORAGE_KEY, this.token)
        localStorage.setItem(RADOM_TOKEN_EXPIRY_LOCAL_STORAGE_KEY, new Date(verifyResponse.expiresAt).toString())
      } catch (err) {
        localStorage.clear()
        window.location = '/' as any
      }

      this.isLoggedIn = !verifyResponse.requires2fa
      this.requires2fa = verifyResponse.requires2fa
      if (!verifyResponse.requires2fa) {
        successToast('Successfully signed in!')
      }

      if (this.token) {
        try {
          const visitorTokenResponse = await this.generateVisitorToken()
          localStorage.setItem('visitorToken', visitorTokenResponse.token)
          await identifyVisitor()
        } catch {}
      } else {
        throw new Error('Token not set')
      }
    } catch (err) {
      console.error('Failed to login', err)
      errorToast(err.reason || err.message)
    }

    this.isLoggingIn = false
  }

  signOut = (): void => {
    localStorage.clear()
    window.location = '/' as any
  }

  getProducts = async (includeArchived: boolean): Promise<IProduct[]> => {
    return await this.get('/products',
      {
        include_archived: includeArchived
      }
    )
  }

  verifyOrganizationWithParent = async(req: { parentOrganizationId: string }): Promise<void> => {
    return await this.post('/organization/verify_with_parent', req)
  }

  listDiscountCodes = async (): Promise<IDiscountCode[]> => {
    return await this.get('/discount_codes')
  }

  getSellerProfile = async (): Promise<IOrganization> => {
    return await this.get('/seller_profile').then((s: IOrganization) => {
      this.sellerProfile = s
      return s
    })
  }

  getOrganizationById = async (id: string): Promise<IOrganization> => {
    return await this.get(`/sellers/${id}`)
  }

  getOrganization = async (): Promise<IOrganization> => {
    return await this.get('/organization')
  }

  createVerification = async (): Promise<IVerification> => {
    return await this.post<IVerification>('/verification')
  }

  getVerification = async (): Promise<IVerification> => {
    const verification = await this.get<IVerification>('/verification')

    if (this.sellerProfile && !this.sellerProfile.verificationId) {
      this.sellerProfile.verificationId = verification.id
      this.sellerProfile.verificationStatus = verification.status
    }

    return verification
  }

  updateVerification = async (verificationId: string, payload: UpdateVerificationPayload = null): Promise<void> => {
    await this.put(`/verification/${verificationId}`, payload)
  }

  uploadVerificationImage = async(verificationId: string, data: any): Promise<string> => {
    return await this.postForm(`/verification/${verificationId}/data`, data)
  }

  updateSellerProfile = async (profile: Partial<IOrganization>): Promise<void> => {
    await this.put('/organization', profile)
  }

  getProduct = async (productId: string): Promise<IProduct> => {
    return await this.get(`/product/${productId}`)
  }

  updateProduct = async (productId: string, productInfo: IProductUpdate): Promise<IProduct> => {
    return await this.put(`/product/${productId}`, productInfo)
  }

  createProduct = async (productInfo: IProductCreate): Promise<IProduct> => {
    return await this.post('/product/create', productInfo)
  }

  createPaymentLink = async (req: CreateUpdatePaymentLinkReq): Promise<IPaymentLink> => {
    return await this.post('/payment_link/create', req)
  }

  createDonationLink = async (donationLinkReq: IDonationLink): Promise<IDonationLink> => {
    return await this.post('/donation_link/create', donationLinkReq)
  }

  updateDonationLink = async (donationLinkReq: IDonationLink): Promise<void> => {
    return await this.put(`/donation_link/${donationLinkReq.id}`, donationLinkReq)
  }

  createDiscountCode = async (discountCodeReq: {
    code: string
    products: string[]
    model: { modelType: string, amountOff: number }
    startsAt?: Date
    expiresAt?: Date
  }): Promise<void> => {
    return await this.post('/discount_code/create', discountCodeReq)
  }

  deleteDiscountCode = async (discountCodeId: string): Promise<void> => {
    return await this.delete(`/discount_code/${discountCodeId}`)
  }

  getPaymentLink = async (paymentLinkId: string): Promise<IPaymentLink> => {
    return await this.get(`/payment_link/${paymentLinkId}`)
  }

  getPayment = async (id: string): Promise<Payment> => {
    return await this.get(`/payment/${id}`)
  }

  listPaymentLinks = async (types: any[] = []): Promise<IPaymentLink[]> => {
    const typesStr = types.map((f, i) => `paymentLinkTypes[${i}]=${f}`).join('&')
    return await this.get(`/payment_links?${typesStr}`)
  }

  getDonationLinks = async (): Promise<IDonationLink[]> => {
    return await this.get('/donation_links')
  }

  getDonationLink = async (id: string): Promise<IDonationLink> => {
    return await this.get(`/donation_link/${id}`)
  }

  updatePaymentLink = async (id: string, req: CreateUpdatePaymentLinkReq): Promise<IPaymentLink> => {
    return await this.put(`/payment_link/${id}`, req)
  }

  deletePaymentLink = async (id: string): Promise<void> => {
    return await this.delete(`/payment_link/${id}`)
  }

  deleteDonationLink = async (id: string): Promise<void> => {
    return await this.delete(`/donation_link/${id}`)
  }

  deleteProduct = async (id: string): Promise<void> => {
    return await this.delete(`/product/${id}`)
  }

  createCustomer = async (customerForm: ICreateCustomer): Promise<Customer> => {
    const data = {
      ...customerForm,
      email: customerForm.email.toLowerCase()
    }
    return await this.post('/customer', data)
  }

  updateCustomer = async (id: string, req: { tags: string[] }): Promise<Customer> => {
    return await this.put(`/customer/${id}`, req)
  }

  getCustomer = async (id: string, networks?: string[]): Promise<Customer> => {
    let networksStr
    if (networks) {
      networksStr = networks.map((f, i) => `networks[${i}]=${f}`).join('&')
    }
    return await this.get(`/customer/${id}${networksStr ? `?${networksStr}` : ''}`)
  }

  deleteCustomer = async (id: string): Promise<void> => {
    return await this.delete(`/customer/${id}`)
  }

  listCustomers = async (offset: number, limit: number, networks: string[], sortBy?: string): Promise<{
    data: Customer[]
    total: number
  }> => {
    const networksStr = networks.map((f, i) => `networks[${i}]=${f}`).join('&')
    return await this.get(`/customers?sortBy=${sortBy}&offset=${offset}&limit=${limit}&${networksStr}`)
  }

  createInvoice = async (data: MultiCreateInvoice): Promise<Invoice> => {
    const invoiceData: ICreateInvoice = {
      ...data,
      customerIds: data.customers.map(c => c.id),
      products: data.products.map(p => {
        return {
          ...p,
          quantity: Number(p.quantity),
          product: {
            ...p.product,
            price: Number(p.product.price)
          }
        }
      }),
      lineItems: data.lineItems.map(l => {
        return {
          ...l,
          quantity: Number(l.quantity),
          price: Number(l.price)
        }
      })
    }
    return await this.post('/invoice', invoiceData)
  }

  getInvoice = async (id: string): Promise<Invoice> => {
    return await this.get(`/invoice/${id}`)
  }

  getSellerInvoices = async (): Promise<ListInvoice[]> => {
    return await this.get('/invoices')
  }

  voidInvoice = async (invoiceId: string): Promise<void> => {
    return await this.delete(`/invoice/${invoiceId}`)
  }

  updateLastBlockAck = async (data: LatestAck[]): Promise<void> => {
    return await this.put('/notifications', data)
  }

  getLastBlockAck = async (): Promise<LatestAck[]> => {
    return await this.get('/notifications')
  }

  generateAPIToken = async (name: string): Promise<IToken> => {
    return await this.post('/api_token', { name })
  }

  generateOneTimePassword = async (): Promise<OtpAuthResponse> => {
    return await this.post('/auth/otp/generate')
  }

  verifyGeneratedOneTimePassword = async (token: string): Promise<OtpAuthResponse> => {
    return await this.post('/auth/otp/verify', { token })
  }

  verifyOneTimePassword = async (token: string): Promise<IAuthTokenResponse> => {
    return await this.post('/auth/otp/login', { token })
  }

  disableOneTimePassword = async (): Promise<void> => {
    return await this.post('/auth/otp/disable')
  }

  deleteAPIToken = async (sessionId: string): Promise<void> => {
    return await this.delete(`/api_token/${sessionId}`)
  }

  listAPITokens = async (): Promise<IToken[]> => {
    return await this.get('/api_tokens')
  }

  getWebhooks = async (addr: string): Promise<IWebhook[]> => {
    return await this.get('/webhooks')
  }

  getWebhook = async (id: string): Promise<IWebhook> => {
    return await this.get(`/webhook/${id}`)
  }

  createWebhook = async (webhookInfo: { url: string }): Promise<IWebhook> => {
    return await this.post('/webhook/create', webhookInfo)
  }

  updateWebhook = async (id: string, webhookInfo: {
    url: string
    verificationKey: string
    isPaused: boolean
    isDeleted: boolean
  }): Promise<void> => {
    await this.put(`/webhook/${id}`, webhookInfo)
  }

  deleteWebhook = async (id: string): Promise<void> => {
    return await this.delete(`/webhook/${id}`)
  }

  getWebhookMessages = async (id: string, limit: number, offset: number): Promise<IWebhookMessage[]> => {
    const params = { limit, offset }
    return await this.get(`/webhook/${id}/messages`, params)
  }

  archiveProduct = async (productId: string, isArchived: boolean): Promise<IProduct> => {
    return await this.put(`/product/${productId}/is_archived/${isArchived}`, isArchived)
  }

  resendWebhookMessageFailures = async (id: string): Promise<IWebhook> => {
    return await this.post(`/webhook/${id}/resend_message_failures`, {})
  }

  resendWebhookMessage = async (id: string, messageId: string): Promise<void> => {
    return await this.post(`/webhook/${id}/message/${messageId}/resend`, {})
  }

  listPermissionRoles = async (): Promise<IPermissionRole[]> => {
    return await this.get('/role')
  }

  deletePermissionRole = async (id: string): Promise<void> => {
    return await this.delete(`/role/${id}`)
  }

  createPermissionRole = async (permissionsRequest: { name: string, permissions: string[] }): Promise<void> => {
    return await this.post('/role', permissionsRequest)
  }

  inviteMember = async (req: { emailAddress?: string, evmAddress?: string, roles: string[] }): Promise<void> => {
    return await this.post('/invite', req)
  }

  listInvites = async (): Promise<TeamInvite[]> => {
    return await this.get('/invite')
  }

  listMembers = async (): Promise<Member[]> => {
    return await this.get('/member')
  }

  deleteInvite = async (inviteId: string): Promise<void> => {
    return await this.delete(`/invite/${inviteId}`)
  }

  editMember = async(userId: string, roles: string[]): Promise<void> => {
    return await this.post(`/member/${userId}`, { roles })
  }

  deleteMember = async (userId: string): Promise<void> => {
    return await this.delete(`/member/${userId}`)
  }

  getPriceQuotes = async(from: string[], to: string[]): Promise<IPriceQuote[]> => {
    const fromCurrencies = from.map((f, i) => `from[${i}]=${f}`).join('&')
    const toCurrencies = to.map((f, i) => `to[${i}]=${f}`).join('&')
    return await this.get(`/price_quotes?${fromCurrencies}&${toCurrencies}`)
  }

  getCheckoutSession = async (id: string): Promise<ICheckoutSession> => {
    return await this.get(
      `/checkout_session/${id}`)
  }

  listCheckoutSessions = async (offset: number, limit: number): Promise<{
    data: ICheckoutSession[]
    total: number
  }> => {
    return await this.get(`/checkout_sessions?limit=${limit}&offset=${offset}`)
  }

  createCheckoutSession = async (req: ICreateCheckoutSession): Promise<any> => {
    return await this.post(
      '/checkout_session',
      req)
  }

  cancelCheckoutSession = async (checkoutSessionId: string): Promise<any> => {
    return await this.delete(`/checkout_session/${checkoutSessionId}`)
  }

  getWalletShare = async(loginType: string, token: string): Promise<WalletShare> => {
    return await this.get(`/get_wallet_share?loginType=${loginType}&token=${token}`)
  }

  storeWalletShare = async(loginType: string, token: string, walletShare: string): Promise<void> => {
    return await this.post('/store_wallet_share', {
      loginType,
      token,
      walletShare
    })
  }

  getPaymentLinkOrder = async(paymentLinkOrderId: string): Promise<PaymentLinkOrder> => {
    return await this.get(`/payment_link_order/${paymentLinkOrderId}`)
  }

  getDonationLinkOrder = async(donationLinkOrderId: string): Promise<DonationLinkOrder> => {
    return await this.get(`/donation_link_order/${donationLinkOrderId}`)
  }

  getManagedBalance = async(): Promise<ManagedBalance[]> => {
    return await this.get('/managed_balance')
  }

  submitWithdrawalRequest = async(
    network: string,
    amount: string,
    withdrawalAccountId: string,
    token?: string
  ): Promise<void> => {
    return await this.post('/withdrawal', {
      network, token, amount, withdrawalAccountId
    })
  }

  listWithdrawals = async(offset: number, limit: number, networks: string[]): Promise<ListWithdrawalsResponse> => {
    const networksStr = networks.map((f, i) => `networks[${i}]=${f}`).join('&')
    return await this.get(`/withdrawal?limit=${limit}&offset=${offset}&${networksStr}`)
  }

  confirmPayout = async(id: string): Promise<void> => {
    return await this.post(`/payout/${id}/confirm`)
  }

  createPayout = async(payouts: CreatePayoutEntry[]): Promise<Payout> => {
    return await this.post('/payout', payouts)
  }

  getPayout = async(id: string): Promise<Payout> => {
    return await this.get(`/payout/${id}`)
  }

  listPayouts = async(offset: number, limit: number, isTestMode: boolean): Promise<ListPayoutsResponse> => {
    return await this.get(`/payouts?limit=${limit}&offset=${offset}&isTestMode=${isTestMode}`)
  }

  listPaginatedEvents = async(offset: number, limit: number, eventTypes: string[], networks: string[]): Promise<{
    data: ManagedEvent[]
    total: number
  }> => {
    const eventTypesStr = eventTypes.map((f, i) => `eventTypes[${i}]=${f}`).join('&')
    const networksStr = networks.map((f, i) => `networks[${i}]=${f}`).join('&')
    return await this.get(`/managed/events?limit=${limit}&offset=${offset}&${eventTypesStr}&${networksStr}`)
  }

  listManagedEvents = async(networks: string[], from: string, to: string): Promise<{
    data: ManagedEvent[]
    total: number
  }> => {
    const networksStr = networks.map((f, i) => `networks[${i}]=${f}`).join('&')
    return await this.get(`/managed/events?from=${from}&to=${to}&${networksStr}`)
  }

  getEvent = async(id: string): Promise<ManagedEvent> => {
    return await this.get(`/managed/event/${id}`)
  }

  getManagedPaymentStatus = async(paymentId: string): Promise<ManagedPaymentStatus> => {
    return await this.get(`/managed_payment_status/${paymentId}`)
  }

  getPaymentSession = async (paymentSessionId: string): Promise<PaymentSession> => {
    return await this.get(`/payment_session/${paymentSessionId}`)
  }

  listSubscriptions = async (
    createdBefore: string,
    cancelledAfter: string,
    expiredAfter: string,
    networks: string[],
    statuses: SubscriptionStatus[],
    offset: number,
    limit: number
  ): Promise<{
    data: ISubscription[]
    total: number
  }> => {
    const networksStr = networks.map((f, i) => `networks[${i}]=${f}`).join('&')
    const statusesStr = statuses.map((f, i) => `statuses[${i}]=${f}`).join('&')
    return await this.get(`/v2/subscriptions?createdBefore=${createdBefore}&cancelledAfter=${cancelledAfter}&expiredAfter=${expiredAfter}&${networksStr}&offset=${offset}&limit=${limit}&${statusesStr}`)
  }

  getSubscription = async (id: string): Promise<ISubscription> => {
    return await this.get(`/subscription/${id}`)
  }

  cancelSubscription = async (id: string): Promise<void> => {
    return await this.post(`/subscription/${id}/cancel`, {})
  }

  getInvite = async (inviteId: string): Promise<Invite> => {
    return await this.get(`/invite/${inviteId}`)
  }

  acceptInvite = async (inviteId: string): Promise<Invite> => {
    return await this.post(`/invite/${inviteId}/accept`)
  }

  listOrganizations = async (): Promise<string[]> => {
    return await this.get('/organizations')
  }

  updateUser = async (req: { defaultOrganizationId: string }): Promise<void> => {
    return await this.put('/user', req)
  }

  emailSignIn = async (email: string, password: string): Promise<IAuthTokenResponse> => {
    return await this.post('/auth/email/login', { email, password })
  }

  startEmailSignUp = async (email: string): Promise<void> => {
    return await this.post('/auth/email/start_signup', { email })
  }

  completeEmailSignUp = async (email: string, password: string, code: string): Promise<IAuthTokenResponse> => {
    return await this.post('/auth/email/complete_signup', { email, password, code })
  }

  startPasswordReset = async (email: string): Promise<void> => {
    return await this.post('/auth/email/start_password_reset', { email })
  }

  completePasswordReset = async (email: string, newPassword: string, code: string): Promise<IAuthTokenResponse> => {
    return await this.post('/auth/email/complete_password_reset', { email, newPassword, code })
  }

  search = async (searchString: string): Promise<SearchResult[]> => {
    return await this.get(`/search?value=${searchString}`)
  }

  export = async (from: string, to: string, columns): Promise<any> => {
    const columnsJoined = columns.map((f, i) => `columns[${i}]=${f}`).join('&')
    return await this.get(`/export?from=${from}&to=${to}&${columnsJoined}`)
  }

  exportBalance = async (from: string, to: string, period: any): Promise<any> => {
    return await this.get(`/export_balance?from=${from}&to=${to}&period=${period}`)
  }

  exportWithdrawals = async (from: string, to: string): Promise<any> => {
    return await this.get(`/export_withdrawals?from=${from}&to=${to}`)
  }

  refund = async (req: RefundRequest): Promise<void> => {
    return await this.post('/refund', req)
  }

  claimTestTokens = async(req: { network: string, token: string, address: string }): Promise<{ txId: string }> => {
    return await this.post('/claim_test_tokens', req)
  }

  createOrganization = async(): Promise<{ organizationId }> => {
    return await this.post('/organization')
  }

  completePayment = async(params: any): Promise<void> => {
    return await this.post('/complete_payment', params)
  }

  updateNotificationSettings = async(newSettings: any): Promise<void> => {
    return await this.post('/notification_settings', newSettings)
  }

  getNotificationSettings = async(): Promise<{ [setting: string]: boolean }> => {
    return await this.get('/notification_settings')
  }

  listWithdrawalAccounts = async(): Promise<WithdrawalAccount[]> => {
    return await this.get('/withdrawal/account')
  }

  deleteWithdrawalAccount = async(id: string): Promise<WithdrawalAccount[]> => {
    return await this.delete(`/withdrawal/account/${id}`)
  }

  createWithdrawalAccount = async(req: {
    name: string
    accountDetails: WithdrawalAccountDetails
  }): Promise<WithdrawalAccount[]> => {
    return await this.post('/withdrawal/account', req)
  }

  convertAsset = async(req: ConvertRequest): Promise<void> => {
    return await this.post('/convert', req)
  }

  listAutomations = async(): Promise<Automation[]> => {
    return await this.get('/automations')
  }

  deleteAutomation = async(id: string): Promise<void> => {
    return await this.delete(`/automation/${id}`)
  }

  createAutomation = async(req: any): Promise<Automation> => {
    return await this.post('/automation', req)
  }

  getUser = async (): Promise<User> => {
    return await axios.get(`${API_BASE_URL}/user`, {
      headers: { Authorization: this.token }
    }).then(res => res.data).catch(err => {
      if (err.response?.status === 401) {
        this.signOut()
      }
    })
  }

  generateVisitorToken = async (): Promise<VisitorTokenResponse> => {
    return await this.post<VisitorTokenResponse>('/visitor_token')
  }

  uploadImage = async(imageData: any): Promise<void> => {
    return await this.postForm('/image', imageData)
  }

  createBridgeCustomer = async (): Promise<BridgeCustomer> => await this.post<BridgeCustomer>('/bridge')

  updateBridgeCustomer = async (
    id: string,
    req: { status: string } | {
      description: string
      isDao: boolean
      transmitsCustomerFunds: boolean
      complianceScreeningExplanation?: string
    }
  ): Promise<void> => {
    await this.put(`/bridge/${id}`, req)
  }

  private readonly postForm = async<T> (path: string, body: any = {}): Promise<T> => {
    const headers: any = {
      Authorization: this.token
    }

    if (this.isLoggedIn) {
      await UserState.getUser()
      headers.RadomOrganization = UserState.selectedOrganizationId
    }

    return await axios.postForm(`${API_BASE_URL}${path}`, body, {

      headers: {
        ...headers,
        'content-type': 'multipart/form-data'
      }
    }).then(res => res.data)
  }

  private readonly post = async<T> (path: string, body: any = {}): Promise<T> => {
    const headers: any = {
      Authorization: this.token
    }

    if (this.isLoggedIn) {
      await UserState.getUser()
      headers.RadomOrganization = UserState.selectedOrganizationId
    }

    return await axios.post(`${API_BASE_URL}${path}`, body, {
      headers
    }).then(res => res.data)
  }

  private readonly put = async<T> (path: string, body: any = {}): Promise<T> => {
    const headers: any = {
      Authorization: this.token,
      'content-type': 'application/json'
    }

    if (this.isLoggedIn) {
      await UserState.getUser()
      headers.RadomOrganization = UserState.selectedOrganizationId
    }

    return await axios.put(`${API_BASE_URL}${path}`, body, {
      headers
    }).then(res => res.data)
  }

  private readonly delete = async<T> (path: string, body: any = {}): Promise<T> => {
    const headers: any = {
      Authorization: this.token
    }

    if (this.isLoggedIn) {
      const user = await UserState.getUser()
      headers.RadomOrganization = user?.defaultOrganizationId
    }

    return await axios.delete(`${API_BASE_URL}${path}`, {
      headers
    }).then(res => res.data)
  }

  private readonly get = async<T> (path: string, params?: any): Promise<T> => {
    const headers: any = {
      Authorization: this.token
    }

    if (this.isLoggedIn) {
      const user = await UserState.getUser()
      headers.RadomOrganization = user?.defaultOrganizationId
    }

    return await axios.get(`${API_BASE_URL}${path}`, {
      headers,
      params
    }).then(res => res.data)
  }
}

export default new Radom()
