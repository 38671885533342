import { Plugin, RenderViewer } from '@react-pdf-viewer/core'

import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import toast, { Renderable, ValueOrFunction } from 'react-hot-toast'
import UserState from '../state/User'

export const shortAddress = (address: string, decimals = 6): string => {
  if (!address) {
    return ''
  }

  return address.substring(0, decimals) + '...' + address.slice(address.length - decimals)
}

export const SecondsToPaymentPeriod = (seconds: number): string => {
  const intervals = {
    1: 'Second',
    60: 'Minute',
    [60 * 60]: 'Hour',
    [60 * 60 * 24]: 'Day',
    [60 * 60 * 24 * 7]: 'Week',
    [60 * 60 * 24 * 30]: 'Month',
    [60 * 60 * 24 * 30 * 3]: 'Quarter',
    [60 * 60 * 24 * 30 * 6]: 'Six Months',
    [60 * 60 * 24 * 365]: 'Year'
  }

  if (intervals[seconds]) {
    return intervals[seconds]
  }

  if (seconds > 60 * 60 * 24 * 365) {
    const years = (seconds / (60 * 60 * 24 * 365)).toFixed(1)
    return `${years} Years`
  }
  if (seconds > 60 * 60 * 24 * 30) {
    const months = (seconds / (60 * 60 * 24 * 30)).toFixed(1)
    return `${months} Months`
  }
  if (seconds > 60 * 60 * 24) {
    const days = (seconds / (60 * 60 * 24)).toFixed(1)
    return `${days} Days`
  }
  if (seconds > 60 * 60) {
    const hours = (seconds / (60 * 60)).toFixed(1)
    return `${hours} Hours`
  }
  if (seconds > 60) {
    const minutes = (seconds / 60).toFixed(1)
    return `${minutes} Minutes`
  }

  return `${seconds} Seconds`
}

export function convertDateToUTC(date: Date): Date {
  return new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds()
  )
}

export function errorToast(message: string): void {
  toast.error(message, { position: 'top-center', style: { fontSize: 14, boxSizing: 'content-box', overflowWrap: 'anywhere' } })
}

export function successToast(message: string): void {
  toast.success(message, { position: 'top-center', style: { fontSize: 14, boxSizing: 'content-box', overflowWrap: 'anywhere' } })
}

export function infoToast(message: string): void {
  toast(message, { position: 'top-center', style: { fontSize: 14, boxSizing: 'content-box', overflowWrap: 'anywhere' } })
}

export async function promiseToast<T>(
  promise: Promise<T>,
  loading: string,
  success: string,
  error: ValueOrFunction<Renderable, any>
): Promise<T> {
  return await toast.promise(
    promise,
    { loading, success, error }, {
      position: 'top-center',
      style: {
        fontSize: 14
      }
    })
}

export const TokenCurrencies = ['USDT', 'USDC', 'ETH']

export const formatCurrency = (amount: number | string, currency: string, decimals: number = 2): string => {
  const effectiveDecimals = decimals !== undefined ? decimals : 2
  const numericAmount = typeof amount === 'number' ? amount : Number(amount)

  if (!TokenCurrencies.includes(currency)) {
    try {
      const options = {
        style: 'currency',
        currency,
        minimumFractionDigits: effectiveDecimals,
        maximumFractionDigits: effectiveDecimals
      }
      const formattedAmount = Intl.NumberFormat('en-US', options).format(numericAmount).replace(/(\.\d*[1-9])0+$|\.0*$/, '$1')

      return formattedAmount
    } catch (err) {
      return Intl.NumberFormat('en-US', { minimumFractionDigits: effectiveDecimals, maximumFractionDigits: effectiveDecimals }).format(amount)
    }
  }

  const tokenFormattedAmount = numericAmount.toFixed(6).replace(/(\.\d*[1-9])0+$|\.0*$/, '$1')

  return `${tokenFormattedAmount} ${currency}`
}

export function simpleEmailValidation(address: string): boolean {
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
  return regex.test(address)
}

function setLocalStorageItemWithEvent(key: string, value?: string): void {
  if (!value) {
    localStorage.removeItem(key)
  } else {
    localStorage.setItem(key, value)
  }

  dispatchEvent(new Event('localStorageItemSet'))
}

const TEST_MODE_KEY = 'testMode'
export function useTestMode(): [boolean, Dispatch<SetStateAction<boolean>>] {
  const [testMode, setTestMode] = useState(!!localStorage.getItem(TEST_MODE_KEY))

  useEffect(() => {
    addEventListener('localStorageItemSet', () => {
      const newTestMode = !!localStorage.getItem(TEST_MODE_KEY)
      setTestMode(newTestMode)
    })
  }, [])

  useEffect(() => {
    setLocalStorageItemWithEvent(TEST_MODE_KEY, testMode ? 'true' : undefined)
  }, [testMode])

  return [testMode, setTestMode]
}

export async function identifyVisitor(): Promise<void> {
  try {
    const user = await UserState.getUser()
    const visitorToken = localStorage.getItem('visitorToken')

    if (user?.emailAddress && visitorToken) {
      window.hsConversationsSettings = {
        identificationEmail: user.emailAddress,
        identificationToken: visitorToken
      }

      if (window.HubSpotConversations?.widget) {
        window.HubSpotConversations.widget.load()
      } else {
        console.error('HubSpotConversations is not available on the window object.')
      }
    } else {
      console.error('User email address or visitor token is not available.')
    }
  } catch (error) {
    console.error('Error identifying visitor:', error)
  }
}

export interface PageThumbnailPluginProps {
  PageThumbnail: React.ReactElement
}

export const pageThumbnailPlugin = (props: PageThumbnailPluginProps): Plugin => {
  const { PageThumbnail } = props

  return {
    renderViewer: (renderProps: RenderViewer) => {
      const { slot } = renderProps

      slot.children = PageThumbnail

      if (!slot.subSlot) {
        slot.subSlot = {}
      }

      // Reset the sub slot
      slot.subSlot.attrs = {}
      slot.subSlot.children = React.createElement(React.Fragment, null)

      return slot
    }
  }
}
